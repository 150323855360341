/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MinusInCircle(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm5,12H7V11H17Z" />
        </SvgIcon>
    );
}
