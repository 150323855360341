/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function InformationCircle1(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1ZM11,6h2V8H11Zm3,12H10V17h1V11H10V10h3v7h1Z" />
        </SvgIcon>
    );
}
