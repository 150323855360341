/**
 * @module webcore-ux/react/components/alpha/Stripev2/controls/RefreshStatus
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SynchronizeIcon from '../../Icons/Synchronize';
import '../../../../style/react/components/Stripev2/RefreshStatus';

const defaultLocales = {
    lessThanOneMin: 'recently',
    minute: ({ minutes }) => `${minutes}m ago`,
    hourAndMinute: ({ hours, minutes }) => `${hours}h ${minutes}m ago`,
    oneDayOrMore: 'over 24h ago',
    never: 'never',
};

const RefreshStatus = ({ lastRefreshTime, locales }) => {
    const [currentTime, setCurrentTime] = useState(moment());

    useEffect(() => {
        let interval = null;
        if (lastRefreshTime) {
            interval = setInterval(() => setCurrentTime(moment()), 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    });

    const getLastUpdatedString = () => {
        const { lessThanOneMin, minute, hourAndMinute, oneDayOrMore, never } = Object.assign({}, defaultLocales, locales);
        if (!lastRefreshTime) {
            return never;
        }

        const duration = moment.duration(currentTime.diff(moment(lastRefreshTime)));
        const timeElapsed = {
            seconds: duration.seconds(),
            minutes: duration.minutes(),
            hours: duration.hours(),
            days: duration.days(),
        };

        /**
         * Helper to call the label and return the result if it's a function, or return it as-is if not.
         * @param {function | string} label - Locale property
         * @returns {string} - String label to be displayed
         */
        const getLabelString = (label) => {
            if (typeof label === 'function') {
                return label(timeElapsed);
            }

            return label;
        };

        if (duration.asSeconds() < 60) {
            return getLabelString(lessThanOneMin);
        }

        const minutes = duration.asMinutes();
        if (minutes >= 1 && minutes < 60) {
            return getLabelString(minute);
        }

        const hours = duration.asHours();
        if (hours >= 1 && hours < 24) {
            return getLabelString(hourAndMinute);
        }

        return getLabelString(oneDayOrMore);
    };

    return (
        <div className={'wcux-refresh-status-container'} data-testid="refresh-status">
            <SynchronizeIcon className={'wcux-refresh-status-sync-icon'} />
            <div className={`wcux-refresh-status-text`}>{getLastUpdatedString()}</div>
        </div>
    );
};

RefreshStatus.defaultProps = {
    locales: {},
};

RefreshStatus.propTypes = {
    /** Locale strings/functions for displaying the label. Functions are for locales requiring interpolation, and have
     * the signature: ({ seconds: number, minutes: number, hours: number, days: number }) -> string.
     * If the time elapsed object is not needed, you can simply provide a string.
     */
    locales: PropTypes.shape({
        /** Never refreshed - displayed when lastRefreshTime is falsy/not provided */
        never: PropTypes.string,
        /** < 1 min ago display */
        lessThanOneMin: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** >= 1 min ago and < 1h display */
        minute: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** >= 1h ago display */
        hourAndMinute: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** > 24h ago display */
        oneDayOrMore: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
    /** Date/time ISO string for when a refresh was last performed */
    lastRefreshTime: PropTypes.string,
};

export default RefreshStatus;
