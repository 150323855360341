/**
 * @file Eye Shown Icon
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const EyeView = (props) => {
    return (
        <SvgIcon {...props} viewBox={'0 0 16 16'}>
            <g id="Box">
                <rect className="cls-1" width="16" height="16" fill="none" />
            </g>
            <g>
                <path
                    fill="#1f1f1f"
                    className="cls-2"
                    d="M8,3C4.85,3,2,4.89,0,8c2,3.08,4.85,5,8,5s6-1.9,8-5C14,4.94,11.15,3,8,3Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,8,11Z"
                />
                <circle fill="#1f1f1f" className="cls-2" cx="7.99" cy="7.98" r="1.5" />
            </g>
        </SvgIcon>
    );
};

export default EyeView;
