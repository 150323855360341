/**
 * @module webcore-ux/react/components/Tooltip
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { default as MaterialTooltip } from '@material-ui/core/Tooltip';
import '../../../style/react/components/Tooltip/Tooltip';

/**
 * Styled version of the Material-UI Tooltip control. See here for API details: https://material-ui.com/api/tooltip/
 * <br>Note: does not work on input fields
 */
export default class Tooltip extends React.Component {
    render() {
        return (
            <MaterialTooltip
                classes={{
                    popper: 'wcux-tooltip-popper',
                    tooltip: 'wcux-tooltip',
                }}
                {...this.props}
            >
                {this.props.children}
            </MaterialTooltip>
        );
    }
}

Tooltip.propTypes = {
    /** tooltip reference element */
    children: PropTypes.element.isRequired,
    /** Text/content to show on the tooltip */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
