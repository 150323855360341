/**
 * @module webcore-ux/react/components/ToggleButton
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MuiToggleButton } from '@material-ui/lab/ToggleButton';
import '../../../style/react/components/ToggleButton/ToggleButton';

/**
 * Toggle button control.
 */
export default class ToggleButton extends React.Component {
    render() {
        const { className, disabled, icon, selected, size, value, variant, ...other } = this.props;

        let buttonClass = 'wcux-toggle-button';

        if (['black', 'ultra-discrete'].includes(variant)) {
            buttonClass = `${buttonClass}-${variant}`;
        }

        let sizeClass = '';

        if (size === 'small') {
            sizeClass = 'wcux-toggle-button-small';
        } else if (size === 'large') {
            sizeClass = 'wcux-toggle-button-large';
        }

        let buttonIcon = null;

        if (icon) {
            if (React.isValidElement(icon)) {
                buttonIcon = <div className="wcux-toggle-button-icon">{icon}</div>;
            } else {
                buttonIcon = (
                    <div className="wcux-toggle-button-icon">
                        <img src={icon} />
                    </div>
                );
            }
        }

        return (
            <MuiToggleButton
                classes={{ selected: 'wcux-toggle-button-selected' }}
                className={classNames('wcux-toggle-button-root', buttonClass, sizeClass, className)}
                disabled={disabled}
                disableRipple={true}
                selected={selected}
                value={value}
                {...other}
            >
                {buttonIcon}
                {this.props.children ? <div className="wcux-toggle-button-content">{this.props.children}</div> : null}
            </MuiToggleButton>
        );
    }
}

ToggleButton.defaultProps = {
    disabled: false,
    size: 'normal',
    variant: 'normal',
};

ToggleButton.propTypes = {
    /** Button content */
    children: PropTypes.node,
    /** CSS class name */
    className: PropTypes.string,
    /** True to disable the button */
    disabled: PropTypes.bool,
    /** Icon to show on the button. Can be an img src string or React node such as svg. */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** True to render the button in the selected state */
    selected: PropTypes.bool,
    /**
     * Size of the button. 'normal' is the same as 'medium', and exists for consistency with the Button component.
     * In the future, 'medium' may be removed.
     */
    size: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
    /** The value to associate with the button when selected in a ToggleButtonGroup */
    value: PropTypes.any.isRequired,
    /** The button variant to use */
    variant: PropTypes.oneOf(['normal', 'black', 'ultra-discrete']),
};
