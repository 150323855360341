/**
 * @module webcore-ux/react/components/ProgressBar
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../../style/react/components/ProgressBar/ProgressBar';
import { default as MaterialLinearProgress } from '@material-ui/core/LinearProgress';

export default class ProgressBar extends React.Component {
    render() {
        const { className, variant, barColorClassName, backgroundColorClassName, value } = this.props;

        return (
            <MaterialLinearProgress
                className={classNames(className, 'wcux-progress-bar')}
                classes={{
                    colorPrimary: classNames(backgroundColorClassName, 'wcux-progress-bar-background-color'),
                    barColorPrimary: classNames(barColorClassName, 'wcux-progress-bar-forecolor'),
                }}
                variant={variant}
                color={'primary'}
                value={value}
            />
        );
    }
}

ProgressBar.defaultProps = {
    variant: 'indeterminate',
};

ProgressBar.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** Type of the loading indicator, either determinate or indeterminate.  Defaults to indeterminate if not specified. */
    variant: PropTypes.oneOf(['indeterminate', 'determinate']),
    /** CSS class name of the bar color for the bar, set background-color: <yourColor> !important; */
    barColorClassName: PropTypes.string,
    /** CSS class name of the background color for the bar, set background-color: <yourColor> !important; */
    backgroundColorClassName: PropTypes.string,
    /** Percentage complete to display on the progress bar, valid range is 0-100.  Only applies to determinate variant */
    value: PropTypes.number,
};
