/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Edit(props) {
    return (
        <SvgIcon {...props}>
            <rect x="3" y="20" width="18" height="2" />
            <path d="M21,6,18,3,16,1h0L3,14v5H8.08L20.52,6.49,21,6ZM7.25,17H5V14.83l8.41-8.41L15.6,8.6Z" />
        </SvgIcon>
    );
}
