/**
 * @module webcore-ux/react/components/ExpansionPanel
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import '../../../style/react/components/ExpansionList/ExpansionPanel';
import classNames from 'classnames';

/**
 * Wrapper for Material-UI Expansion Panels
 */
export default class ExpansionPanel extends React.Component {
    render() {
        const {
            className,
            children,
            headerIcon,
            summary,
            details,
            expanded,
            expandIcon,
            disableCollapse,
            applyDetailedStyles,
            ...other
        } = this.props;

        const selectors = classNames('wcux-expansion-panel', className, {
            'collapse-disabled': disableCollapse,
            'detailed-styles': applyDetailedStyles,
        });

        if (children) {
            return (
                <MuiExpansionPanel {...other} expanded={disableCollapse || expanded} className={selectors} square={true}>
                    {children}
                </MuiExpansionPanel>
            );
        }

        let expandIconNode = null;
        if (expandIcon) {
            expandIconNode = expandIcon;
        } else if (!disableCollapse) {
            expandIconNode = <ExpandMoreIcon />;
        }

        let headerIconNode = null;
        if (typeof headerIcon === 'string') {
            headerIconNode = <img src={headerIcon} alt="Header icon" />;
        } else if (headerIcon) {
            headerIconNode = headerIcon;
        }

        return (
            <MuiExpansionPanel {...other} expanded={disableCollapse || expanded} className={selectors} square={true}>
                <ExpansionPanelSummary expandIcon={expandIconNode}>
                    {headerIconNode ? <div className="wcux-expansion-panel-header-icon-container">{headerIconNode}</div> : null}
                    <span className="wcux-expansion-panel-summary-text">{summary}</span>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>{details}</ExpansionPanelDetails>
            </MuiExpansionPanel>
        );
    }
}

ExpansionPanel.propTypes = {
    /** ClassName to append to the expansion panel's root */
    className: PropTypes.string,
    /**
     * The expansion panel content children. Exclusive with summary, details, expandIcon.
     */
    children: PropTypes.any,
    /**
     * URL of an icon, or an icon node, to display next to the header summary text.
     */
    headerIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    /**
     * The text or node that should appear in the header. If using this, do not provide children.
     */
    summary: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    /**
     * The text or node that should appear in the body. If using this, do not provide children.
     */
    details: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    /**
     * Optional: supply a custom expand icon to be used. Falls back to ExpandMoreIcon.  If using this, do not provide children.
     */
    expandIcon: PropTypes.node,
    /**
     * Externally control the expanded state.
     */
    expanded: PropTypes.bool,
    /**
     * When true, the expansion panel will always be open and cannot be collapsed.
     */
    disableCollapse: PropTypes.bool,
    /**
     * Whether to apply detailed styles such as heavier borders, boxshadow, etc. to this component, eg. for clearer visual differentiation between panels.
     */
    applyDetailedStyles: PropTypes.bool,
};
