/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CaretLeftArrow(props) {
    return (
        <SvgIcon {...props}>
            <polygon points="8.97 12.01 14.93 6.01 15.97 7.06 11.07 12.01 15.97 16.96 14.93 18.01 8.97 12.01" />
        </SvgIcon>
    );
}
