/**
 * @file DataGrid with selectable rows
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import 'react-table/react-table.css';
import { Checkbox } from '..';
import '../../../style/react/components/DataGrid/DataGrid';
import { ReactTableDefaults } from 'react-table';

const SelectTable = selectTableHOC(ReactTable);
const ROW_SELECT_COLUMN_ID = '_selector';
const SELECT_COLUMN_WIDTH = 40;

/**
 * Component to organize the select row functionality. As a type of DataGrid, not meant to be used standalone,
 * this component is ignored in the Styleguidist config.
 * @param {object} props - Props
 * @returns {SelectDataGrid} - Selectable DataGrid JSX
 */
export function SelectDataGrid(props) {
    const tableRef = useRef();
    const { selectedRows, onSelectionChanged, keyField, data, pageSize, ThComponent } = props;
    // Previous selected row index determines where to start/end when toggling multiple rows (eg. by shift click)
    const [prevSelectedRowIndex, setPrevSelectedRowIndex] = useState(0);
    const [currentPageData, setCurrentPageData] = useState([]);
    const [isInitialized, setInitialized] = useState(false);

    const refreshCurrentPageData = () => {
        // Get current page/sorted data from the table state so that toggling select-all affects only the displayed rows
        const { sortedData, page, pageSize } = tableRef.current.getWrappedInstance().getResolvedState();
        const currentIndex = page * pageSize;
        const pageData = sortedData.slice(currentIndex, currentIndex + pageSize).map((item) => item._original);
        setCurrentPageData(pageData);
    };

    useEffect(() => {
        setPrevSelectedRowIndex(0);
        refreshCurrentPageData();
    }, [data]);

    /**
     * Calls the onSelectionChanged callback with the new selection if implemented
     * @param {object} newSelectedRows - Object containing the new selected rows, shaped { rowID: true }
     */
    const handleSelectionChange = (newSelectedRows) => {
        if (typeof onSelectionChanged === 'function') {
            onSelectionChanged(newSelectedRows);
        }
    };

    /**
     * Clear rows that are no longer visible
     * @param {number} newPageSize - Number of rows selected by user
     */
    const onPageSizeChange = (newPageSize) => {
        const newSelected = {};
        currentPageData.slice(0, newPageSize).forEach((row) => {
            const id = row[keyField];
            if (isSelected(id)) {
                newSelected[id] = true;
            }
        });

        if (Object.keys(newSelected).length !== Object.keys(selectedRows).length) {
            handleSelectionChange(newSelected);
        }

        if (prevSelectedRowIndex >= newPageSize) {
            setPrevSelectedRowIndex(0);
        }

        refreshCurrentPageData();
    };

    // Check controlled page size change
    useEffect(() => {
        if (isInitialized && pageSize !== undefined) {
            // Prevent this from running unnecessarily on mount
            onPageSizeChange(pageSize);
        } else {
            setInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);

    const isSelected = (id) => Boolean(selectedRows[id]);
    const isSelectingAllRows = Boolean(currentPageData.length) && currentPageData.every((row) => isSelected(row[keyField]));

    /**
     * Toggle selection on a single row
     * @param {number} index - Index of the row, used for potential multiple selection
     * @param {string} rowId - The id of the row as specified by the keyField
     */
    const toggleSelection = (index, rowId) => {
        const newSelected = { ...selectedRows };
        if (newSelected[rowId]) {
            delete newSelected[rowId];
        } else {
            newSelected[rowId] = true;
        }

        handleSelectionChange(newSelected);
        setPrevSelectedRowIndex(index);
    };

    /**
     * Toggle selection on all visible rows
     */
    const handleToggleAll = () => {
        const newSelected = { ...selectedRows };

        if (isSelectingAllRows) {
            currentPageData.forEach((row) => delete newSelected[row[keyField]]);
        } else {
            currentPageData.forEach((row) => (newSelected[row[keyField]] = true));
        }

        handleSelectionChange(newSelected);
    };

    /**
     * Toggle multiple row selections within a range specified by the previous selected row and the current selected row
     * Whether they become selected or deselected depends on the clicked row. If the clicked row is toggled on, all rows
     * in the range are also toggled on, and vice-versa.
     * @param {number} index - Index of the row, used to determine the selection range
     * @param {string} rowId - The row id (as specified by the keyField property)
     */
    const toggleMultiple = (index, rowId) => {
        const [start, end] = [prevSelectedRowIndex, index].sort((a, b) => a - b);
        const newSelected = { ...selectedRows };
        const isSelected = selectedRows[rowId];
        for (let i = start; i <= end; ++i) {
            const id = currentPageData[i][keyField];
            if (isSelected) {
                delete newSelected[id];
            } else {
                newSelected[id] = true;
            }
        }

        handleSelectionChange(newSelected);
        setPrevSelectedRowIndex(index);
    };

    /**
     * Override ReactTable's select-all checkbox with Webcore's
     * @param {object} props - Props passed by ReactTable to the checkbox component
     * @returns {Checkbox} - Webcore Checkbox with change handler to toggle all rows
     */
    // eslint-disable-next-line react/prop-types
    const getSelectAllInput = ({ checked }) => {
        return <Checkbox checked={checked} onChange={handleToggleAll} />;
    };

    /**
     * Override ReactTable's select row checkbox with Webcore's
     * @param {object} props - Props passed by ReactTable to the checkbox component
     * @returns {Checkbox} - Webcore Checkbox with change handler to toggle the specific row
     */
    // eslint-disable-next-line react/prop-types
    const getSelectInput = ({ checked, row }) => {
        const handleChange = ({ event }) => {
            const rowId = row[keyField];
            const index = currentPageData.findIndex((row) => row[keyField] === rowId);
            if (event.nativeEvent.shiftKey) {
                toggleMultiple(index, rowId);
            } else {
                toggleSelection(index, rowId);
            }
        };

        return <Checkbox checked={checked} onChange={handleChange} />;
    };

    /**
     * Table row props to, eg., add touch/click callbacks to select the row
     * @param {object} tableState - Current table state
     * @param {object} row - Row data to get the id
     * @returns {object} - Table row props object accepted by ReactTable
     */
    const getTrProps = (tableState, row) => {
        // eslint-disable-next-line react/prop-types
        const { original } = row;
        const rowId = original[keyField];
        const { onClick, className, ...otherProps } = typeof props.getTrProps === 'function' ? props.getTrProps(tableState, row) : {};

        return {
            ...otherProps,
            onClick: (e, handleOriginal) => {
                const index = currentPageData.findIndex((pageDataRow) => pageDataRow[keyField] === rowId);
                if (e.shiftKey) {
                    toggleMultiple(index, rowId);
                } else if (e.ctrlKey) {
                    toggleSelection(index, rowId);
                }

                if (typeof onClick === 'function') {
                    onClick(e);
                }

                if (handleOriginal) {
                    handleOriginal();
                }
            },
            className: classNames(className, {
                selected: isSelected(rowId),
            }),
        };
    };

    /**
     * Custom props for the select-all checkbox container
     * @param {object} tableState - Current table state
     * @param {object} row - Row data
     * @param {object} column - Column data
     * @returns {object} - Th props object accepted by ReactTable
     */
    const getThProps = (tableState, row, column) => {
        const { getTheadThProps } = props;
        const baseProps = typeof getTheadThProps === 'function' ? getTheadThProps(tableState, row, column) : {};
        if (column.id === ROW_SELECT_COLUMN_ID) {
            return {
                ...baseProps,
                className: 'wcux-select-all-container',
            };
        }

        return baseProps;
    };

    /**
     * Always return the ReactTable default for the row selection column so that it can't be rearranged
     * @param {object} state - Th state data
     * @returns {JSX.Element} - Th JSX
     */
    const thComponent = (state) => {
        if (state.id === ROW_SELECT_COLUMN_ID || !ThComponent) {
            return ReactTableDefaults.ThComponent(state);
        }

        return ThComponent(state);
    };

    /**
     * Apply props to the select-all checkbox container
     * @param {object} tableState - Current table state
     * @param {object} row - Row data
     * @param {object} column - Column data
     * @returns {object} - Th props object accepted by ReactTable
     */
    const getTdProps = (tableState, row, column) => {
        const { getTdProps } = props;
        const baseProps = typeof getTdProps === 'function' ? getTdProps(tableState, row, column) : {};
        if (column.id === ROW_SELECT_COLUMN_ID) {
            return {
                ...baseProps,
                className: 'wcux-select-container',
            };
        }

        return baseProps;
    };

    /**
     * Simplifies selection behaviour for page change and sorting
     */
    const clearSelection = () => {
        handleSelectionChange({});
        setPrevSelectedRowIndex(0);
        refreshCurrentPageData();
    };

    return (
        <SelectTable
            keyField={keyField}
            {...props}
            data={data}
            selectAll={isSelectingAllRows}
            isSelected={isSelected}
            SelectAllInputComponent={getSelectAllInput}
            SelectInputComponent={getSelectInput}
            getTrProps={getTrProps}
            getTheadThProps={getThProps}
            getTdProps={getTdProps}
            selectWidth={SELECT_COLUMN_WIDTH}
            ref={tableRef}
            onPageChange={clearSelection}
            onPageSizeChange={onPageSizeChange}
            onSortedChange={clearSelection}
            ThComponent={thComponent}
        />
    );
}

/**
 * Mostly an extension of DataGrid.propTypes
 */
SelectDataGrid.propTypes = {
    selectedRows: PropTypes.object.isRequired,
    onSelectionChanged: PropTypes.func.isRequired,
    keyField: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    getTheadThProps: PropTypes.func,
    getTrProps: PropTypes.func,
    getTdProps: PropTypes.func,
    pageSize: PropTypes.number,
    ThComponent: PropTypes.func,
};
