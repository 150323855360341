/**
 * @module webcore-ux/react/components/Button
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialButton } from '@material-ui/core/Button';
import '../../../style/react/components/Button/Button';

/**
 * Button control.
 * Any props other than the ones documented (i.e. id, name, disabled, onClick, style) will be spread to the root element.
 */
export default class Button extends React.Component {
    render() {
        const { className, icon, size, variant, ...other } = this.props;

        let buttonVariant = 'outlined',
            buttonClass = 'wcux-button-normal';

        if (variant === 'primary') {
            buttonVariant = 'contained';
            buttonClass = 'wcux-button-primary';
        } else if (variant === 'discrete') {
            buttonVariant = 'text';
            buttonClass = 'wcux-button-discrete';
        } else if (variant === 'primary-black') {
            buttonVariant = 'contained';
            buttonClass = 'wcux-button-primary-black';
        } else if (variant === 'discrete-black') {
            buttonVariant = 'text';
            buttonClass = 'wcux-button-discrete-black';
        } else if (variant === 'ultra-discrete-black') {
            buttonVariant = 'text';
            buttonClass = 'wcux-button-ultra-discrete-black';
        }

        let sizeClass = '';

        if (size === 'small') {
            sizeClass = 'wcux-button-small';
        } else if (size === 'large') {
            sizeClass = 'wcux-button-large';
        }

        let buttonIcon = null;

        if (icon) {
            if (React.isValidElement(icon)) {
                buttonIcon = <div className="wcux-button-icon">{icon}</div>;
            } else {
                buttonIcon = (
                    <div className="wcux-button-icon">
                        <img src={icon} />
                    </div>
                );
            }
        }

        return (
            <div className={classNames('wcux-button-root', className)}>
                <MaterialButton className={classNames(buttonClass, sizeClass)} variant={buttonVariant} disableRipple={true} {...other}>
                    {buttonIcon}
                    {this.props.children ? <div className="wcux-button-content">{this.props.children}</div> : null}
                </MaterialButton>
            </div>
        );
    }
}

Button.defaultProps = {
    size: 'normal',
    variant: 'normal',
};

Button.propTypes = {
    /** Button content */
    children: PropTypes.node,
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** Icon to show on the button. Can be an img src string or React node such as svg. */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Size of the button */
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    /** The button variant to use */
    variant: PropTypes.oneOf(['primary', 'normal', 'discrete', 'primary-black', 'discrete-black', 'ultra-discrete-black']),
};
