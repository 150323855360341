/**
 * @module webcore-ux/tableColumnUtils for adding common utility functions for DataGrid and ReadOnlyTable
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import { ToggleSwitch } from './react/components';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

/**
 * Get the display for a tristate/boolean field
 *
 * @param {string} type - type of column i.e. boolean or tristate
 * @param {any} value - value of the column being rendered
 * @param {boolean} useToggleSwitch - show toggleSwitch or not
 * @returns {JSX.Element} - returns empty or checkbox depending on type and value
 */
export const renderTristateOrBoolean = (type, value, useToggleSwitch) => {
    if (['boolean', 'tristate'].includes(type)) {
        if (type === 'tristate' && (value === undefined || value === null || value === '' || typeof value === 'object')) {
            return '';
        } else {
            if (type === 'boolean' && useToggleSwitch) {
                return <ToggleSwitch disabled={true} checked={value} />;
            } else if (value === true || value === 'true' || (typeof value === 'number' && value !== 0)) {
                return <CheckBoxIcon />;
            } else {
                return <CheckBoxOutlineBlankIcon />;
            }
        }
    }

    throw new Error(`Config type ${type} not supported while rendering boolean or tristate.`);
};

/**
 * Get the display for a number field
 *
 * @param {any} value - The cell value
 * @returns {string} - Render string
 */
export const renderNumber = (value) => {
    if (value === undefined || value === null || value === '' || typeof value === 'object') {
        return '';
    } else {
        let num = Number(value);

        if (isNaN(num)) {
            return '';
        }

        return num.toLocaleString();
    }
};
