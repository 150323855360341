/**
 * @file Breadcrumb.js Contains config functions to add/remove breadcrumbid to xhr calls.
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import { v4 as uuidv4 } from 'uuid';

export const originalXhrOpen = XMLHttpRequest.prototype.open;

/**
 * Adds breadcrumbid with uuid when XMLHttpRequest.prototype.open is called
 * @param {string[]} excludePaths - Instrumentation key to use with ApplicationInsights script
 */
export function initializeBreadcrumb(excludePaths = []) {
    (function (open) {
        XMLHttpRequest.prototype.open = function (method, url, hasBreadcrumb = true) {
            open.call(this, method, url);

            // exclude breadcrumbid if url contains any string from excludePaths.
            const validUrl = !excludePaths.some(path => url.includes(path));
            if (hasBreadcrumb && validUrl) {
                let breadcrumbId = uuidv4();
                this.setRequestHeader("breadcrumbid", breadcrumbId);
            }
        };
    })(XMLHttpRequest.prototype.open);
}

/**
 * Reverts XMLHttpRequest.prototype.open to original function
 */
export function uninitializeBreadcrumb() {
    XMLHttpRequest.prototype.open = originalXhrOpen;
}


export default {
    originalXhrOpen,
    initializeBreadcrumb,
    uninitializeBreadcrumb
};
