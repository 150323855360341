/**
 * @file General utility functions
 * @copyright © Copyright 2019 ABB. All rights reserved.
 * @module Utils
 */

/**
 * @typedef {string} responseType
 */
/**
 * @enum {responseType}
 */
export const RESPONSE_TYPE = {
    JSON: 'json',
    TEXT: 'text'
};

/**
 * Send a request, 200 and 204 will resolve, all others will be rejects.
 * @param {string} method - request method
 * @param {string} url - request url
 * @param {string|object} body - request body
 * @param {function} getToken - Function returns a Promise which resolves the token
 * @param {number} [timeout=60000] - The timeout to use, defaults to 60000 if not specified.
 * @param {responseType} [responseType] - optional response type (default text)
 * @returns {Promise<string|object|undefined>} promise
 */
export function send(method, url, body, getToken, timeout = 60000, responseType = RESPONSE_TYPE.TEXT) {
    if (getToken) {
        return getToken(30).then((token) => sendRequest(method, url, body, token, timeout, responseType));
    } else {
        return sendRequest(method, url, body, undefined, timeout, responseType);
    }
}

/**
 * Sends the request with or without the token, 200 or 204 will resolve, all others reject.
 *
 * @private
 * @param {string} method - request method
 * @param {string} url - request url
 * @param {string|object} body - request body
 * @param {string} [token] - The token to send, or no token if undefined
 * @param {number} timeout - The timeout to use, in ms.
 * @param {responseType} responseType - responseType for the request
 * @returns {Promise<string|object|undefined>} promise
 */
function sendRequest(method, url, body, token, timeout, responseType) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = responseType;

        xhr.open(method, url);
        xhr.timeout = timeout;
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Cache-Control', 'no-cache');

        if (token) {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        }

        xhr.onerror = () => {
            if (responseType === RESPONSE_TYPE.JSON) {
                let ret = {
                    status: xhr.status,
                    statusText: xhr.statusText
                };

                try {
                    ret.body = typeof xhr.response === 'string' ? JSON.parse(xhr.response) : xhr.response;
                } catch (e) {
                    ret.body = {};
                }

                reject(ret);
            } else {
                reject(xhr.statusText);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200 || xhr.status === 202) {
                if (responseType === RESPONSE_TYPE.JSON) {
                    try {
                        resolve(typeof xhr.response === 'string' ? JSON.parse(xhr.response) : xhr.response);
                    } catch (e) {
                        resolve({});
                    }
                } else {
                    resolve(xhr.responseText);
                }
            } else if (xhr.status === 204) {
                resolve(undefined);
            } else {
                xhr.onerror();
            }
        };

        xhr.ontimeout = () => {
            if (responseType === RESPONSE_TYPE.JSON) {
                return reject({
                    status: 0, // 0 means unsent or opened
                    statusText: 'Request Timed Out',
                    body: null
                });
            } else {
                return reject('Request Timed Out');
            }
        };

        if (body) {
            if (typeof body === 'string') {
                xhr.send(body);
            } else {
                xhr.send(JSON.stringify(body));
            }
        } else {
            xhr.send();
        }
    });
}

export default {
    send
};
