/**
 * @module webcore-ux/react/components/SlidePanel
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer/Drawer';
import CloseIcon from '../../../react/components/Icons/Close';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import '../../../style/react/components/SlidePanel/SlidePanel';
import classNames from 'classnames';
import PinIcon from '../../../react/components/Icons/Pin';
import UnpinIcon from '../../../react/components/Icons/Unpin';

const ANCHOR_OPTIONS = ['left', 'right', 'top', 'bottom'];

/**
 * Slide Panel component that slides in from left/right/bottom/top side of the screen
 * @returns {JSX.Element} SlidePanel component
 */
// eslint-disable-next-line react/display-name
const SlidePanel = React.forwardRef(
    (
        {
            open,
            anchor,
            className,
            theme,
            title,
            variant,
            onBack,
            onClose,
            onPin,
            showBackButton,
            showCloseButton,
            showPinButton,
            children,
            ...other
        },
        ref
    ) => {
        const [showPin, setShowPin] = useState(true);
        let paperClass = theme === 'dark' ? 'wcux-slidepanel-paper-dark' : 'wcux-slidepanel-paper-light';

        if (showPinButton && showPin) {
            paperClass += ` wcux-slidepanel-pinned-${ANCHOR_OPTIONS.includes(anchor) ? anchor : 'left'}`;
        }

        return (
            <Drawer
                ref={ref}
                anchor={anchor}
                open={open}
                variant={variant}
                className={classNames('wcux-slidepanel-root', className)}
                classes={{ paper: paperClass }}
                onClose={onClose}
                {...other}
            >
                <Toolbar className="wcux-slidepanel-toolbar" disableGutters={true} variant="dense">
                    <Box className="wcux-slidepanel-left">
                        {showBackButton && (
                            <IconButton
                                className={classNames('wcux-slidepanel-button', 'wcux-slidepanel-back-button')}
                                disableRipple={true}
                                onClick={onBack}
                            >
                                <BackIcon fontSize="small" color="action" />
                            </IconButton>
                        )}
                    </Box>

                    <Box className="wcux-slidepanel-center">
                        {title && (
                            <Typography align="center" className="wcux-slidepanel-title" variant="h1" color="inherit" noWrap>
                                {title}
                            </Typography>
                        )}
                    </Box>

                    <Box className="wcux-slidepanel-right">
                        {showPinButton && (
                            <IconButton
                                className={classNames(
                                    'wcux-slidepanel-button',
                                    showPin ? 'wcux-slidepanel-pin-button' : 'wcux-slidepanel-unpin-button'
                                )}
                                disableRipple={true}
                                onClick={() => {
                                    onPin(showPin);
                                    setShowPin(!showPin);
                                }}
                            >
                                {showPin ? <PinIcon fontSize="small" color="action" /> : <UnpinIcon fontSize="small" color="action" />}
                            </IconButton>
                        )}
                        {showCloseButton && (
                            <IconButton
                                className={classNames('wcux-slidepanel-button', 'wcux-slidepanel-close-button')}
                                disableRipple={true}
                                onClick={() => {
                                    onClose();
                                    setShowPin(true);
                                }}
                            >
                                <CloseIcon fontSize="small" color="action" />
                            </IconButton>
                        )}
                    </Box>
                </Toolbar>
                <div className="wcux-slidepanel-content">{children}</div>
            </Drawer>
        );
    }
);

SlidePanel.defaultProps = {
    open: false,
    anchor: 'left',
    theme: 'light',
    variant: 'persistent',
    showBackButton: false,
    showPinButton: false,
    showCloseButton: true,
};

SlidePanel.propTypes = {
    /** Side from which the slide panel will appear */
    anchor: PropTypes.oneOf(ANCHOR_OPTIONS),
    /** Content of the slide panel to add */
    children: PropTypes.node,
    /** ClassName to apply to the slide panel's root */
    className: PropTypes.string,
    /** Indicates if the slide panel should be open or not. The caller is responsible for handling this state. */
    open: PropTypes.bool.isRequired,
    /** Back button handler the caller must implement if the showBackButton prop is set to true */
    onBack: PropTypes.func,
    /** Close button handler the caller must implement if the showCloseButton prop is set to true */
    onClose: PropTypes.func,
    /** Pin button handler the caller must implement if the showPinButton prop is set to true */
    onPin: PropTypes.func,
    /** Indicator to show back button on the slide panel. */
    showBackButton: PropTypes.bool,
    /** Indicator to show close button on the slide panel. */
    showCloseButton: PropTypes.bool,
    /** Indicator to show pin button on the slide panel. */
    showPinButton: PropTypes.bool,
    /** Theme to be used for the slide panel */
    theme: PropTypes.oneOf(['dark', 'light']),
    /** Title to be displayed for the slide panel */
    title: PropTypes.string,
    /** Variant of the slide panel to use */
    variant: PropTypes.oneOf(['persistent', 'temporary']),
};

export default SlidePanel;
