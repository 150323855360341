/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Download(props) {
    return (
        <SvgIcon {...props}>
            <polygon className="cls-2" points="12 18 17.5 12.5 16 11 13 14 13 2 11 2 11 14 8 11 6.5 12.5 12 18" />
            <path className="cls-2" d="M21,16v4H3V16H1v4a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V16Z" />
        </SvgIcon>
    );
}
