/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CaretDownArrow(props) {
    return (
        <SvgIcon {...props}>
            <polygon points="12 15 6 9.04 7.05 8 12 12.9 16.95 8 18 9.04 12 15" />
        </SvgIcon>
    );
}
