/**
 * @module webcore-ux/react/components/Charts/BarChart
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { BarChart as RechartBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../../../../style/react/components/Charts/BarChart';

export default class BarChart extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            height,
            width,
            dataKey,
            labelName,
            xAxisKey,
            barFill,
            barSize,
            legendPosition,
            marginTop,
            marginBottom,
            marginRight,
            marginLeft,
            dataLabelName,
        } = this.props;

        return (
            <div
                style={{
                    width: width + marginLeft + marginRight,
                    minWidth: 0,
                    height: height + marginTop + marginBottom,
                }}
            >
                <div>
                    <label className={'wcux-chart-label'}>{labelName}</label>
                </div>
                <ResponsiveContainer>
                    <RechartBarChart
                        className={'wcux-barchart'}
                        width={width}
                        height={height}
                        data={this.props.input}
                        margin={{
                            top: marginTop,
                            right: marginRight,
                            left: marginLeft,
                            bottom: marginBottom,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey={xAxisKey} tick={this.props.customizedAxisTick} interval={0} />
                        <YAxis />
                        <Tooltip />
                        <Legend height={25} verticalAlign={legendPosition} />
                        <Bar
                            name={dataLabelName}
                            className={'wcux-bar'}
                            dataKey={dataKey}
                            onClick={this.props.onClick}
                            barSize={barSize}
                            label={{ position: 'top', fill: '#000000' }}
                            fill={barFill}
                        />
                    </RechartBarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

BarChart.defaultProps = {
    barFill: '#77A3FC',
    barSize: 30,
    legendPosition: 'top',
    marginTop: 20,
    marginBottom: 50,
    marginLeft: 20,
    marginRight: 30,
};

BarChart.propTypes = {
    className: PropTypes.string,
    //Display name or title for chart
    labelName: PropTypes.string,
    //data set to plot the chart
    input: PropTypes.array.isRequired,
    //required to set the height of the chart
    width: PropTypes.number.isRequired,
    //required to set the height of the chart
    height: PropTypes.number.isRequired,
    //Required to provide that key name from the dataset for y axis plot for metric
    dataKey: PropTypes.string.isRequired,
    //to give your own display/label name for Yaxis data to show up for legend and tooltip
    dataLabelName: PropTypes.string,
    //Required to provide that key name from the dataset for rendering labels for x axis plot
    xAxisKey: PropTypes.string.isRequired,
    //to add your own functionality for onClick handler on bar and is optional
    onClick: PropTypes.func,
    //to add your own color to bar
    barFill: PropTypes.string,
    //to add your own color to bar
    barSize: PropTypes.number,
    //to add customized xaxis tick
    customizedAxisTick: PropTypes.any,
    //to add legend position,default is top
    legendPosition: PropTypes.string,
    //to provide margin length for the margin top
    marginTop: PropTypes.number,
    //to provide margin length for the margin bottom
    marginBottom: PropTypes.number,
    //to provide margin length for the margin right
    marginRight: PropTypes.number,
    //to provide margin length for the margin left
    marginLeft: PropTypes.number,
};
