/**
 * @module webcore-ux/react/components/TabContainer
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tabs as MuiTabs } from '@material-ui/core';
import '../../../style/react/components/Tabs/TabContainer';

export default class TabContainer extends React.Component {
    render() {
        const { className, value, onChange, children, variant, layoutVariant, ...other } = this.props;

        let variantClass;

        switch (variant) {
            case 'secondary':
                variantClass = 'wcux-variant-secondary';
                break;
            case 'panel':
                variantClass = 'wcux-variant-panel';
                break;
            default:
                variantClass = 'wcux-variant-primary';
        }

        return (
            <MuiTabs
                component="div"
                value={value}
                onChange={onChange}
                className={classNames('wcux-tab-container', variantClass, className)}
                variant={layoutVariant}
                textColor={'inherit'}
                {...other}
            >
                {children}
            </MuiTabs>
        );
    }
}

TabContainer.defaultProps = {
    value: false,
    layoutVariant: 'fullWidth',
    variant: 'primary',
};

TabContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    /** CSS class name to apply */
    className: PropTypes.string,
    /** The selected tab or false if you don't want a selected tab */
    value: PropTypes.any.isRequired,
    /** onChange event fired when the user changes tabs.  (tab, value) => { ... } is the syntax */
    onChange: PropTypes.func,
    /** layoutVariant determines how to handle the width, or if to add scrollbuttons.  Value values are: standard, fullWidth, scrollable.  Default is fullWidth */
    layoutVariant: PropTypes.oneOf(['standard', 'fullWidth', 'scrollable']),
    /** variant determines how to display the tab headers.  primary, seconary or panel.  Defaults to primary. */
    variant: PropTypes.oneOf(['primary', 'secondary', 'panel']),
};
