/**
 * @module webcore-ux/react/components/Dialog
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialDialog } from '@material-ui/core/Dialog';
import Button from '../Button/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '../../../react/components/Icons/Close';
import '../../../style/react/components/Dialog/Dialog';

/**
 * Dialog control
 */
export default class Dialog extends React.Component {
    render() {
        const {
            actionButtons,
            className,
            disableEscapeKeyDown,
            disableBackdropClick,
            onClose,
            open,
            showCloseButton,
            title,
            headerIcon,
            variant,
            ...other
        } = this.props;

        let dialogHeader,
            dialogContent,
            modeClassName,
            dialogActionButton = null;

        if (Array.isArray(actionButtons) && actionButtons.length > 0) {
            dialogActionButton = (
                <div className={'wcux-dialog-button-container'}>
                    {actionButtons.map((button, index) => {
                        return (
                            <Button
                                key={button.key || index}
                                size="small"
                                variant={button.variant}
                                className={classNames('wcux-dialog-action-button', button.className)}
                                onClick={button.handleClick}
                                disabled={button.disabled}
                                data-testid={button.key}
                            >
                                {button.text}
                            </Button>
                        );
                    })}
                </div>
            );
        }

        dialogContent = <div className={'wcux-dialog-content'}>{this.props.children}</div>;

        if (variant === 'complex') {
            modeClassName = 'wcux-dialog-complex-mode';

            dialogHeader = (
                <div className={'wcux-dialog-header-container'}>
                    <div className={'wcux-dialog-header'}>
                        {headerIcon && <div className="wcux-dialog-header-icon">{headerIcon}</div>}
                        {title}
                    </div>
                    {dialogActionButton}
                </div>
            );

            dialogActionButton = null; // no action button at the footer for complex
        } else {
            // regular dialog

            modeClassName = 'wcux-dialog-regular-mode';

            dialogHeader = (
                <div className={'wcux-dialog-header'}>
                    {headerIcon && <div className="wcux-dialog-header-icon">{headerIcon}</div>}
                    {title}
                    {showCloseButton && (
                        <IconButton className={'wcux-dialog-close-button'} onClick={() => onClose()}>
                            <CloseIcon fontSize="small" color="action" />
                        </IconButton>
                    )}
                </div>
            );
        }

        return (
            <MaterialDialog
                open={open}
                onClose={onClose}
                disableBackdropClick={disableBackdropClick}
                disableEscapeKeyDown={disableEscapeKeyDown}
                className={classNames('wcux-dialog-root', modeClassName, className)}
                classes={{ paper: 'wcux-dialog-ui-paper' }}
                {...other}
            >
                {dialogHeader}
                {dialogContent}
                {dialogActionButton}
            </MaterialDialog>
        );
    }
}

Dialog.defaultProps = {
    disableEscapeKeyDown: false,
    disableBackdropClick: false,
    open: false,
    showCloseButton: true,
    variant: 'regular',
};

Dialog.propTypes = {
    /** Array of objects containing Button configuration.
     * The button config supports: key, variant, className, handleClick, text, disabled.
     */
    actionButtons: PropTypes.array,
    /** Content of the Dialog along with the styling to be added */
    children: PropTypes.node,
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** If true, hitting escape will not fire the onClose callback. */
    disableEscapeKeyDown: PropTypes.bool,
    /** If true, clicking the backdrop will not fire the onClose callback. */
    disableBackdropClick: PropTypes.bool,
    /** Indicates if the menu should be open or not.  The caller is responsible for handling this state */
    open: PropTypes.bool.isRequired,
    /** Callback fired when the component requests to be closed.
     * Signature:
     *  function(event: object, reason: string) => void
     *  event: The event source of the callback.
     *  reason: Can be:"escapeKeyDown", "backdropClick" */
    onClose: PropTypes.func,
    /** Indicator to show close button on the Dialog. */
    showCloseButton: PropTypes.bool,
    /** Title of the Dialog */
    title: PropTypes.string,
    /** Indicates whether the Dialog is a regular or a complex one */
    variant: PropTypes.oneOf(['regular', 'complex']),
    /** Icon to show on the header. Can be an img src string or React node such as svg. */
    headerIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
