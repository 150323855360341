/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WarningCircle1(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm1,17H11V16h2Zm0-4H11V6h2Z" />
        </SvgIcon>
    );
}
