/**
 * @module webcore-ux/react/components/Dropdown
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckMarkCircle1 from '../Icons/CheckMarkCircle1';
import ErrorCircle1 from '../Icons/ErrorCircle1';
import WarningCircle1 from '../Icons/WarningCircle1';
import Search from '../Icons/Search';
import '../../../style/react/components/Dropdown/Dropdown';
import Select, { components } from 'react-select';
import memoizeOne from 'memoize-one';
import { Button } from '../';
import Highlighter from 'react-highlight-words';
import AsyncPaginate from 'react-select-async-paginate';
import Logger from 'abb-webcore-logger/Logger';
import { getValueFromObj } from 'webcore-common';

/**
 * All styles should be in .scss file.
 * Dont use makeStyles in this file because it prevents it to stop re-rendering dropdown(Ex in ReplayApp)
 */

/**
 * Formats the options list so that every option has a label
 * @param {array} options - Unformatted options list
 * @returns {array} Formatted options list
 */
const constructOptionList = memoizeOne((options) =>
    Array.isArray(options) ? options.map(({ label, value }) => ({ label: label || String(value), value })) : []
);

/**
 * Return the value(s) from the option(s)
 * @param {Object|Object[]|null} value - Option(s)
 * @returns {*} Value or array of values
 */
const transformOutValue = (value) => {
    if (value === undefined || value === null) return value;

    if (Array.isArray(value)) {
        return value.map(({ value }) => value);
    } else if (typeof value === 'object') {
        return value.value;
    }
};

const SelectContainer = ({ children, ...props }) => {
    return (
        <components.SelectContainer {...props} innerProps={{ ...props.innerProps, 'data-testid': props.selectProps.name }}>
            {children}
        </components.SelectContainer>
    );
};

SelectContainer.propTypes = {
    children: PropTypes.array,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
};

/**
 * Dropdown component
 * @returns {JSX.Element} Dropdown component
 */
const Dropdown = ({
    className,
    defaultValue,
    value,
    id,
    isClearable,
    isDisabled,
    readOnly,
    label,
    placeholder,
    mandatory,
    description,
    confirmation,
    error,
    warning,
    isMulti,
    onChange,
    name,
    options,
    onActionButtonClick,
    actionButtonIcon,
    isLoading,
    isSearchable,
    loadOptions,
    optionsProps,
}) => {
    // Get the new option list
    const newOptions = constructOptionList(options);

    const [filteredOptions, setFilteredOptions] = useState(null);

    useEffect(() => {
        setFilteredOptions(constructOptionList(options));
    }, [options]);

    const selectRef = useRef();

    let message = null;

    if (error && typeof error === 'string') {
        // render error message if provided
        message = (
            <div className="wcux-validation-message">
                <ErrorCircle1 className="wcux-validation-icon" />
                {error}
            </div>
        );
    } else if (warning && typeof warning === 'string') {
        // render warning message if provided
        message = (
            <div className="wcux-validation-message">
                <WarningCircle1 className="wcux-validation-icon" />
                {warning}
            </div>
        );
    } else if (confirmation && typeof confirmation === 'string') {
        // render confirmation message if provided
        message = (
            <div className="wcux-validation-message">
                <CheckMarkCircle1 className="wcux-validation-icon" />
                {confirmation}
            </div>
        );
    } else if (description) {
        message = <div className="wcux-validation-message">{description}</div>;
    }

    /**
     * Return the option object(s) that match the value(s)
     * @param {*} value - Value or array of values
     * @param {Object[]} options - Options list
     * @param {Boolean} isMulti - Determines if this is a multi-select
     * @returns {Object|Object[]} Option object or array of option objects
     */
    const transformInValue = (value, options, isMulti) => {
        if (value === undefined) return value;

        const findMatchingOption = (value) => {
            // get the value from the latest fetched options since for async select
            // the `newOptions` array is not updated with the latest options
            if (loadOptions && selectRef.current) {
                const result = selectRef.current.select.props.options.find(function (option) {
                    return option.value === value;
                });

                if (result) return result;

                if (Array.isArray(selectRef.current.select.props.value)) {
                    return selectRef.current.select.props.value.find(function (option) {
                        return option.value === value;
                    });
                }

                return selectRef.current.select.props.value && selectRef.current.select.props.value.value === value
                    ? selectRef.current.select.props.value
                    : null;
            }

            return options.find((option) => option.value === value);
        };

        if (value === null) {
            let option = findMatchingOption(null);

            if (option) {
                return isMulti ? [option] : option;
            }

            return null;
        } else if (isMulti) {
            if (Array.isArray(value)) {
                return value.map(findMatchingOption);
            }

            return null;
        } else {
            let option = findMatchingOption(value);

            return option ? option : null;
        }
    };

    /**
     * onClick handler for the action button
     */
    const handleActionButtonClick = () => {
        onActionButtonClick(name);
    };

    const formatOptionLabel = ({ label }, { inputValue }) => {
        return (
            <Highlighter
                highlightClassName="wcux-dropdown-highlighter"
                searchWords={[inputValue]}
                textToHighlight={label}
                autoEscape={true}
            />
        );
    };

    //onInputChange for filtering searched value and ordering options
    const onInputChange = (value, { action }) => {
        if (action === 'input-change') {
            setFilteredOptions(
                newOptions
                    .filter(({ label }) => label.toLowerCase().includes(value.toLowerCase()))
                    .sort((a, b) => {
                        return a.label.toLowerCase().indexOf(value.toLowerCase()) - b.label.toLowerCase().indexOf(value.toLowerCase());
                    })
            );
        } else {
            setFilteredOptions(newOptions);
        }
    };

    const loadOptionsFn = async (search, loadedOptions) => {
        let result = [],
            hasMore = false;

        if (loadOptions) {
            try {
                const res = await loadOptions(search, loadedOptions);

                res.results.forEach((element) => {
                    result.push({
                        label: getValueFromObj(element, optionsProps.displayField),
                        value: getValueFromObj(element, optionsProps.valueField),
                    });
                });
                hasMore = res.hasMore || false;
            } catch (error) {
                Logger.error(error);
            }
        }

        // New options will be appended to the current dropdown list if the results are fetched while scrolling.
        // So, no need to concat with the loadedOptions.
        // In case the search value is not blank, no concatenation will be done and the `result` array will be used as the current options.
        return {
            options: result,
            hasMore,
        };
    };

    const commonProps = {
        placeholder,
        defaultValue: transformInValue(defaultValue, newOptions, isMulti),
        value: transformInValue(value, newOptions, isMulti),
        isClearable,
        isSearchable,
        isDisabled: isDisabled || readOnly,
        menuPlacement: 'auto',
        components: { SelectContainer },
        className: classNames(
            'wcux-dropdown-container',
            { 'wcux-dropdown-multiselect-container': isMulti },
            { 'wcux-dropdown-readonly': readOnly }
        ),
        classNamePrefix: 'wcux-dropdown',
        name,
        id,
        onChange: (selected) => {
            if (!onChange) return;

            onChange({ id, name, value: transformOutValue(selected) });
        },
        isMulti,
        // For async-select, the initial options list is already fetched so use it as it is.
        // Otherwise, there were some issues on using the state options `filteredOptions`.
        options: loadOptions ? newOptions : filteredOptions,
        formatOptionLabel,
    };

    let SelectComponent = <Select {...commonProps} isLoading={isLoading} onInputChange={onInputChange} />;

    if (loadOptions) {
        SelectComponent = <AsyncPaginate {...commonProps} loadOptions={loadOptionsFn} selectRef={selectRef} />;
    }

    return (
        <div
            className={classNames('wcux-dropdown-root', className, {
                'wcux-validation-error': error,
                'wcux-validation-warning': !error && warning,
                'wcux-validation-confirmation': !error && !warning && confirmation,
            })}
        >
            {!!label && (
                <label htmlFor={id} className={classNames('wcux-label', { 'wcux-mandatory-indicator': mandatory })}>
                    {label}
                </label>
            )}
            <div className="wcux-dropdown-action-container">
                {SelectComponent}
                {typeof onActionButtonClick === 'function' && (
                    <Button
                        className="wcux-dropdown-action-button"
                        size="small"
                        onClick={handleActionButtonClick}
                        icon={actionButtonIcon ? actionButtonIcon : <Search />}
                    />
                )}
            </div>
            {message}
        </div>
    );
};

Dropdown.defaultProps = {
    isClearable: true,
    isMulti: false,
    isSearchable: true,
};

Dropdown.propTypes = {
    /** Class name for the dropdown control wrapper */
    className: PropTypes.string,
    /** true to show a confirmation indicator or a string to show a confirmation indicator and message */
    confirmation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Value(s) that will be selected by default; array if isMulti */
    defaultValue: PropTypes.any,
    /**  Dropdown value(s), required for a controlled component; array if isMulti */
    value: PropTypes.any,
    /** Text to display below the dropdown */
    description: PropTypes.string,
    /** Label to display */
    label: PropTypes.string,
    /** id for the dropdown control */
    id: PropTypes.string,
    /** name for the dropdown control */
    name: PropTypes.string,
    /** true to display the clear button */
    isClearable: PropTypes.bool,
    /** true to disable the dropdown */
    isDisabled: PropTypes.bool,
    /** true to make the dropdown read only */
    readOnly: PropTypes.bool,
    /** Options for the dropdown control including the labels and their corresponding values, e.g. [{ label: 'Priority0', value: 'P0' }, { label: 'Priority1', value: 'P1' }] */
    options: PropTypes.array.isRequired,
    /** Placeholder to display when no option is selected */
    placeholder: PropTypes.string,
    /** Function to handle dropdown change event. Signature: function(selectedValueObject) */
    onChange: PropTypes.func,
    /** true to show indication that a field is mandatory */
    mandatory: PropTypes.bool,
    /** true to show an error indicator or a string to show an error indicator and message */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** true to show a warning indicator or a string to show a warning indicator and message */
    warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** true to allow multiple selection of values; if true, the defaultValue and value props must be type array, and if false, defaultValue and value must be type string */
    isMulti: PropTypes.bool,
    /** Callback when action button is clicked */
    onActionButtonClick: PropTypes.func,
    /** action button icon */
    actionButtonIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** true to display loading indicator */
    isLoading: PropTypes.bool,
    /** true to enable search/filter on options */
    isSearchable: PropTypes.bool,
    /**
     * Function to load options on menu open as well as while scrolling. Basically used for fetching from an API.
     * @param {string} searchValue - Current input value of the dropdown
     * @param {Array} loadedOptions - Already loaded options in the dropdown
     * @returns {Promise} A Promise which returns an object containing an array of newly fetched options in the "results" key and a boolean "hasMore" to depict more options exist to be fetched
     */
    loadOptions: PropTypes.func,
    /** Object containing fields used for the `label` and `value` of the dropdown option */
    optionsProps: PropTypes.shape({
        displayField: PropTypes.string,
        valueField: PropTypes.string,
    }),
};

export default Dropdown;
