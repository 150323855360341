/**
 * @module webcore-ux/react/components/Form
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import memoize from 'memoize-one';
import Grid from '@material-ui/core/Grid';
import { ConfigType, PageType } from './Constants';
import { renderDisplayField } from './DisplayField';
import {
    Input,
    ImageGrid,
    GeoLocation,
    TabContainer,
    Tab,
    Checkbox,
    Tristate,
    DayRangePicker,
    ExpansionPanel,
    ToggleSwitch,
    ReadOnlyTable,
} from '../';
import '../../../style/react/components/Form/Form';
import cloneDeep from 'lodash.clonedeep';
import Plus from '../Icons/Plus';
import Trash from '../Icons/Trash';
import Edit from '../Icons/Edit';
import { convertToReadOnlyTableColumnDefinitions } from './FormUtil';
import FormDropdown from './FormDropdown';

/**
 * Component for creating dynamic forms from config
 */
export default class Form extends React.Component {
    constructor(props) {
        super(props);

        this.getStringResource = this.getStringResource.bind(this);
        // used to check mandatory error fields outside this component
        this.getError = this.getError.bind(this);
        this.validateAllIfChanged = memoize((record, validation) => this.validateAll(record, validation));
        const defaultExpansionPanelState = this.getDefaultExpansionPanelState();

        this.state = {
            prevRecord: null,
            hasChanges: false,
            showErrors: false,
            selectedTab: 0,
            expansionPanelsExpanded: defaultExpansionPanelState,
        };

        this.mandatoryErrors = {};
        this.validationErrors = {};

        this.fieldsWithPattern = [];
        this.fieldsWithMinLength = [];
    }

    static getDerivedStateFromProps(props, state) {
        if (props.record !== state.prevRecord) {
            return {
                prevRecord: props.record,
            };
        }

        return null;
    }

    render() {
        const { className, config, record, validation } = this.props;

        // This should only run during the initial render and if the record or validation prop changes
        this.validateAllIfChanged(record, validation);

        if (!config || config.type !== ConfigType.FORM || !Array.isArray(config.children) || config.children.length < 1) {
            throw new Error('Invalid form config');
        }

        let pageType = PageType.SINGLE;

        if (config.pageType) {
            pageType = config.pageType;
        }

        let page;

        switch (pageType) {
            case PageType.SINGLE:
                page = this.renderPage(config.children[0]);
                break;

            case PageType.TABS:
                page = this.renderTabs(config);
                break;

            case PageType.CAROUSEL:
                throw new Error(`Page type ${pageType} not supported yet`);

            default:
                throw new Error(`Invalid page type ${pageType}`);
        }

        return <div className={classNames('wcux-form', className)}>{page}</div>;
    }

    /**
     * Render a form page
     *
     * @param {object} config - page config
     * @returns {React.Component|HTMLDivElement} page
     */
    renderPage(config) {
        if (config.type !== ConfigType.PAGE || !Array.isArray(config.children)) {
            throw new Error('Invalid page configuration');
        }

        let title = null;

        if (config.title) {
            title = <div className="wcux-page-title">{this.getStringResource(config.title)}</div>;
        }

        return (
            <div className="wcux-page">
                {title}
                {this.renderExpansionPanelsToggler(config)}
                {this.renderPageGrid(config)}
            </div>
        );
    }

    /**
     * Renders a form with tabs at the top.
     *
     * @param {object} config - the Config object for the Tabs
     * @returns {React.Component|HTMLDivElement} page
     */
    renderTabs(config) {
        return (
            <div className="wcux-page">
                <TabContainer
                    value={this.state.selectedTab}
                    className={'wcux-page-tab-container'}
                    variant={config.tabVariant}
                    onChange={(tab, index) => {
                        this.setState({ selectedTab: index });
                    }}
                >
                    {config.children.map((item, index) => {
                        return (
                            <Tab
                                key={index}
                                className={classNames(`wcux-page-tab-${index}`)}
                                value={index}
                                label={this.getStringResource(item.title)}
                            />
                        );
                    })}
                </TabContainer>
                {this.state.selectedTab !== false && this.renderExpansionPanelsToggler(config.children[this.state.selectedTab])}
                {this.state.selectedTab !== false && (
                    <div className="wcux-page-tab-content">{this.renderPageGrid(config.children[this.state.selectedTab])}</div>
                )}
            </div>
        );
    }

    /**
     * Renders Grid that contains sections, used when rendering page.
     *
     * @param {object} config - the Config object for the Page
     * @returns {React.Component|HTMLDivElement} page
     */
    renderPageGrid(config) {
        return <Grid container>{this.renderSections(config)}</Grid>;
    }

    /**
     * Renders a collection of sections
     *
     * @param {object} config - form config containing children sections
     * @returns {React.Component[]} - Array of sections
     */
    renderSections(config) {
        let sections = [];

        config.children.forEach((sectionConfig, index) => {
            let section = this.renderSection(sectionConfig, index);
            sections.push(section);
        }, this);

        return sections;
    }

    /**
     * Render a form section
     *
     * @param {object} config - section config
     * @param {number} key - index of section
     * @returns {React.Component} section
     */
    renderSection(config, key) {
        const {
            type,
            direction,
            supportedPlatforms,
            groupboxDisplay,
            headerIcon,
            isCollapsible,
            disableCollapse,
            defaultExpanded,
            fieldToShowWhenCollapsed,
            xs,
            sm,
            md,
            lg,
            xl,
        } = config;

        const { expansionPanelsExpanded, selectedTab } = this.state;

        if (type !== ConfigType.SECTION || !Array.isArray(config.children)) {
            throw new Error('Invalid section configuration');
        }

        // Section is rendered in all platforms unless specified otherwise
        if (Array.isArray(supportedPlatforms) && supportedPlatforms.length > 0) {
            let supported = false;

            if (window.cordova) {
                supported = supportedPlatforms.includes('cordova');
            } else {
                supported = supportedPlatforms.includes('web');
            }

            if (!supported) {
                return null;
            }
        }

        let children = [];

        config.children.forEach((childConfig, index) => {
            let child;

            // simply do not render anything if field exists in the hiddenFields
            if (!(Array.isArray(this.props.hiddenFields) && this.props.hiddenFields.includes(childConfig.name))) {
                let newChildConfig = cloneDeep(childConfig);

                // enforce validation on pattern (RegExp match)
                if (newChildConfig.pattern) {
                    this.fieldsWithPattern[newChildConfig.name] = new RegExp(newChildConfig.pattern);
                }

                // enforce validation on min length
                if (newChildConfig.minLength) {
                    this.fieldsWithMinLength[newChildConfig.name] = newChildConfig.minLength;
                }

                if (Array.isArray(this.props.mandatoryFields) && this.props.mandatoryFields.includes(newChildConfig.name)) {
                    newChildConfig.mandatory = true;
                }

                if (Array.isArray(this.props.readOnlyFields) && this.props.readOnlyFields.includes(newChildConfig.name)) {
                    newChildConfig.readOnly = true;
                }

                switch (newChildConfig.type) {
                    case ConfigType.INPUT:
                        child = this.renderTextInput(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.NUMBER:
                        child = this.renderNumberInput(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.DROPDOWN:
                        child = this.renderDropdown(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.DATE:
                    case ConfigType.TIME:
                    case ConfigType.DATETIME:
                        child = this.renderDateTime(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.DATERANGE:
                        child = this.renderDateRange(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.IMAGEGRID:
                        child = this.renderImageGrid(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.BOOLEAN:
                        child = this.renderBoolean(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.TRISTATE:
                        child = this.renderTristate(newChildConfig, newChildConfig.name || index);
                        break;
                    case ConfigType.FILE_ATTACHMENTS:
                        child = this.renderFileAttachment(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.TEXT_DISPLAY:
                    case ConfigType.NUMBER_DISPLAY:
                    case ConfigType.DATETIME_DISPLAY:
                    case ConfigType.DATE_DISPLAY:
                    case ConfigType.TIME_DISPLAY:
                    case ConfigType.BOOLEAN_DISPLAY:
                    case ConfigType.HYPERLINK_DISPLAY:
                    case ConfigType.LISTITEM_DISPLAY:
                    case ConfigType.PHONENUMBER_DISPLAY:
                    case ConfigType.LITERAL_DISPLAY:
                    case ConfigType.STATUS_DISPLAY:
                    case ConfigType.REFERENCE_DISPLAY:
                    case ConfigType.TABLE_DISPLAY:
                        child = renderDisplayField(
                            newChildConfig,
                            this.props.configData,
                            this.props.record,
                            {
                                getStringResource: (key) => this.getStringResource(key),
                                handleMoreClick: (name) => this.handleMoreClick(name),
                                ...this.props.callbacks,
                            },
                            newChildConfig.name || index
                        );

                        break;

                    case ConfigType.GEOLOCATION:
                        child = this.renderGeoLocation(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.TOGGLESWITCH:
                        child = this.renderToggleSwitch(newChildConfig, newChildConfig.name || index);
                        break;

                    case ConfigType.TABLE:
                        if (
                            this.props.callbacks &&
                            this.props.callbacks[ConfigType.TABLE] &&
                            this.props.callbacks[ConfigType.TABLE].onAdd &&
                            this.props.callbacks[ConfigType.TABLE].onEdit &&
                            this.props.callbacks[ConfigType.TABLE].onDelete
                        ) {
                            child = this.renderEditableTable(
                                newChildConfig,
                                newChildConfig.name || index,
                                this.props.record,
                                this.props.callbacks[ConfigType.TABLE].onAdd,
                                this.props.callbacks[ConfigType.TABLE].onEdit,
                                this.props.callbacks[ConfigType.TABLE].onDelete
                            );
                        }

                        break;

                    case ConfigType.REFERENCE:
                        if (
                            this.props.callbacks &&
                            this.props.callbacks[ConfigType.REFERENCE] &&
                            this.props.callbacks[ConfigType.REFERENCE].onRender
                        ) {
                            const handleChange = (name, value) => this.handleChange(name, value);

                            if (newChildConfig.name) {
                                const value = this.props.record.getValue(newChildConfig.name);
                                this.setMandatoryError(
                                    ConfigType.REFERENCE,
                                    newChildConfig.mandatory,
                                    newChildConfig.name,
                                    value,
                                    newChildConfig.description,
                                    this.getStringResource(newChildConfig.label)
                                );
                            }

                            const error = this.showError(newChildConfig.name);

                            child = this.props.callbacks[ConfigType.REFERENCE].onRender({
                                key: newChildConfig.name || index,
                                config: newChildConfig,
                                record: this.props.record,
                                handleChange,
                                error,
                            });
                        }

                        break;

                    default:
                        throw new Error(`Control type ${newChildConfig.type} not supported`);
                }

                children.push(child);
            } else {
                //Reset mandatory error for fields which are now hidden and previously mandatory.
                this.setMandatoryError(
                    childConfig.type,
                    childConfig.mandatory,
                    childConfig.name,
                    this.props.record.getValue(childConfig.name),
                    childConfig.description
                );
            }
        }, this);

        if (isCollapsible) {
            let collapsedFieldInfo;

            // only viewer
            if (fieldToShowWhenCollapsed && fieldToShowWhenCollapsed.type) {
                switch (fieldToShowWhenCollapsed.type) {
                    case ConfigType.TEXT_DISPLAY:
                    case ConfigType.NUMBER_DISPLAY:
                    case ConfigType.DATETIME_DISPLAY:
                    case ConfigType.DATE_DISPLAY:
                    case ConfigType.TIME_DISPLAY:
                    case ConfigType.BOOLEAN_DISPLAY:
                    case ConfigType.HYPERLINK_DISPLAY:
                    case ConfigType.LISTITEM_DISPLAY:
                    case ConfigType.PHONENUMBER_DISPLAY:
                    case ConfigType.LITERAL_DISPLAY:
                    case ConfigType.STATUS_DISPLAY:
                    case ConfigType.REFERENCE_DISPLAY:
                        collapsedFieldInfo = renderDisplayField(
                            fieldToShowWhenCollapsed,
                            this.props.configData,
                            this.props.record,
                            {
                                getStringResource: (key) => this.getStringResource(key),
                                handleMoreClick: (name) => this.handleMoreClick(name),
                                ...this.props.callbacks,
                            },
                            fieldToShowWhenCollapsed.name
                        );

                        break;

                    default:
                        collapsedFieldInfo = null;
                }
            }

            return (
                <Grid item key={key} className="wcux-section" xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <ExpansionPanel
                        defaultExpanded={defaultExpanded}
                        disableCollapse={disableCollapse}
                        summary={
                            expansionPanelsExpanded[selectedTab][key]
                                ? this.getStringResource(config.title)
                                : config.fieldToShowWhenCollapsed
                                ? this.getCollapsedTitle(config.title, collapsedFieldInfo)
                                : this.getStringResource(config.title)
                        }
                        headerIcon={headerIcon}
                        details={
                            <div className="wcux-expansion-panel-form-grid-wrapper">
                                <Grid container direction={direction}>
                                    {children}
                                </Grid>
                            </div>
                        }
                        onChange={(event, expanded) => this.handleExpansionPanelChange(config, expanded, key)}
                        expanded={expansionPanelsExpanded[selectedTab][key]}
                        applyDetailedStyles={true}
                    />
                </Grid>
            );
        }

        const getGridContents = () => {
            const contents = (
                <>
                    {config.title ? <div className="wcux-section-title">{this.getStringResource(config.title)}</div> : null}
                    <Grid container direction={direction}>
                        {children}
                    </Grid>
                </>
            );

            return groupboxDisplay ? <div className="wcux-section-groupbox">{contents}</div> : contents;
        };

        return (
            <Grid item key={key} className="wcux-section" xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                {getGridContents()}
            </Grid>
        );
    }

    /**
     * Render a text input control
     *
     * @param {object} config - input config
     * @param {string} key - key
     * @returns {Input} input control
     */
    renderTextInput(config, key) {
        const {
            id,
            label,
            placeholder,
            description,
            mandatory,
            name,
            readOnly,
            disabled,
            multiline,
            rows,
            minLength,
            maxLength,
            pattern,
            showCounter,
        } = config;

        let value, handleChange;
        const translatedLabel = this.getStringResource(label);
        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.INPUT, mandatory, name, value, description, translatedLabel);
            handleChange = (event) => this.handleChange(name, event.target.value);
        }

        const inputAttr = {
            minLength: minLength,
            maxLength: maxLength,
            pattern: pattern,
        };

        return (
            <Input
                key={key}
                id={id}
                name={name}
                readOnly={readOnly}
                disabled={disabled}
                label={translatedLabel}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                multiline={multiline}
                rows={rows}
                inputAttr={inputAttr}
                value={value}
                onChange={handleChange}
                error={this.showError(name)}
                showCounter={showCounter}
            />
        );
    }

    /**
     * Render a GeoLocation control
     *
     * @param {object} config - GeoLocation config
     * @param {string} key - key
     * @returns {GeoLocation} GeoLocation control
     */
    renderGeoLocation(config, key) {
        const {
            id,
            label,
            placeholder,
            description,
            mandatory,
            name,
            permissionError,
            unavailableError,
            timeoutError,
            defaultError,
            accuracyThreshold,
            exceedThresholdMsg,
            captureInProgressMsg,
            applyButtonText,
            cancelButtonText,
            readOnly,
        } = config;

        let value, handleChange;
        const translatedLabel = this.getStringResource(label);
        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.GEOLOCATION, mandatory, name, value, description, translatedLabel);
            handleChange = (event) => this.handleChange(name, event.value);
        }

        return (
            <GeoLocation
                key={key}
                id={id}
                name={name}
                label={translatedLabel}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                value={value}
                onChange={handleChange}
                error={this.showError(name)}
                permissionError={permissionError ? this.getStringResource(permissionError) : undefined}
                unavailableError={unavailableError ? this.getStringResource(unavailableError) : undefined}
                timeoutError={timeoutError ? this.getStringResource(timeoutError) : undefined}
                defaultError={defaultError ? this.getStringResource(defaultError) : undefined}
                accuracyThreshold={accuracyThreshold}
                exceedThresholdMsg={exceedThresholdMsg ? this.getStringResource(exceedThresholdMsg) : undefined}
                captureInProgressMsg={captureInProgressMsg ? this.getStringResource(captureInProgressMsg) : undefined}
                applyButtonText={applyButtonText ? this.getStringResource(applyButtonText) : undefined}
                cancelButtonText={cancelButtonText ? this.getStringResource(cancelButtonText) : undefined}
                readOnly={readOnly}
            />
        );
    }

    /**
     * Render a ToggleSwitch control
     *
     * @param {object} config - ToggleSwitch config
     * @param {string} key - key
     * @returns {ToggleSwitch} ToggleSwitch control
     */
    renderToggleSwitch(config, key) {
        const { name, label, id, readOnly, disabled, checked } = config;

        let value, handleChange;

        if (name) {
            value = this.props.record.getValue(name);
            handleChange = (event) => this.handleChange(name, event.isChecked);
        }

        return (
            <ToggleSwitch
                key={key}
                id={id}
                readOnly={readOnly}
                disabled={disabled}
                name={name}
                label={this.getStringResource(label)}
                onChange={handleChange}
                checked={checked !== undefined ? checked : value}
            />
        );
    }

    /**
     * Render a number input control
     *
     * @param {object} config - input config
     * @param {string} key - key
     * @returns {Input} input control
     */
    renderNumberInput(config, key) {
        const { id, label, placeholder, description, mandatory, name, readOnly, disabled, max, min, integerOnly } = config;
        const inputAttr = { max, min };
        let value, handleChange;
        const translatedLabel = this.getStringResource(label);

        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.NUMBER, mandatory, name, value, description, translatedLabel);
            handleChange = (event) => this.handleChange(name, event.target.value);
        }

        return (
            <Input
                type="number"
                key={key}
                id={id}
                name={name}
                readOnly={readOnly}
                disabled={disabled}
                label={translatedLabel}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                value={value}
                onChange={handleChange}
                error={this.showError(name)}
                inputAttr={inputAttr}
                integerOnly={integerOnly}
            />
        );
    }

    /**
     * Render a dropdown control
     *
     * @param {object} config - dropdown config
     * @param {string} key - key
     * @returns {Dropdown} dropdown control
     */
    renderDropdown(config, key) {
        const {
            id,
            label,
            placeholder,
            description,
            data,
            mandatory,
            name,
            readOnly,
            disabled,
            isClearable,
            isMulti,
            showActionButton,
        } = config;

        let value, handleChange;

        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.DROPDOWN, mandatory, name, value, description, this.getStringResource(label));
            handleChange = (selected) => this.handleChange(name, selected.value);
        }

        return (
            <FormDropdown
                key={key}
                id={id}
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                label={label}
                placeholder={placeholder}
                description={description}
                mandatory={mandatory}
                isClearable={isClearable}
                isMulti={isMulti}
                value={value}
                onChange={handleChange}
                showError={this.showError(name)}
                onActionButtonClick={showActionButton ? (name) => this.handleDropdownActionButtonClick(name) : undefined}
                actionButtonIcon={this.getActionButtonIcon(name)}
                getStringResource={this.getStringResource}
                dataConfig={data}
                formDataConfig={this.props.configData}
                record={this.props.record}
                hostUrl={this.props.hostUrl}
                getToken={this.props.getToken}
            />
        );
    }

    /**
     * Render a date/time/datetime control
     *
     * @param {object} config - datetime config
     * @param {string} key - key
     * @returns {Input} date/time/datetime control
     */
    renderDateTime(config, key) {
        const { type, id, label, placeholder, description, mandatory, name, readOnly, disabled } = config;

        let dtType = type;

        if (dtType === ConfigType.DATETIME) {
            dtType = 'datetime-local';
        }

        let value, handleChange;
        const translatedLabel = this.getStringResource(label);
        if (name) {
            value = this.props.record.getValue(name);
            this.setMandatoryError(ConfigType.DATETIME, mandatory, name, value, description, translatedLabel);
            handleChange = (event) => this.handleChange(name, event.target.value);
        }

        return (
            <Input
                key={key}
                type={dtType}
                id={id}
                name={name}
                readOnly={readOnly}
                disabled={disabled}
                label={translatedLabel}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                value={value}
                onChange={handleChange}
                error={this.showError(name)}
            />
        );
    }

    renderDateRange(config, key) {
        const { id, placeholder, description, mandatory, startName, endName, readOnly, disabled, format } = config;
        const { record } = this.props;
        const sValue = record.getValue(startName);
        const eValue = record.getValue(endName);
        this.setMandatoryError(ConfigType.DATERANGE, mandatory, startName, sValue, description);
        this.setMandatoryError(ConfigType.DATERANGE, mandatory, endName, eValue, description);

        const handleStartChange = (value) => this.handleChange(startName, value);
        const handleEndChange = (value) => this.handleChange(endName, value);
        return (
            <DayRangePicker
                key={key}
                id={id}
                startName={startName}
                endName={endName}
                start={sValue}
                end={eValue}
                readOnly={readOnly}
                disabled={disabled}
                placeholder={this.getStringResource(placeholder)}
                description={this.getStringResource(description)}
                mandatory={mandatory}
                format={format}
                onStartChange={handleStartChange}
                onEndChange={handleEndChange}
                error={this.showError(startName) || this.showError(endName)}
            />
        );
    }

    /**
     * Render an image grid
     *
     * @param {object} config - image grid config
     * @param {string} key - key
     * @returns {ImageGrid} - image grid
     */
    renderImageGrid(config, key) {
        const { cellHeight, columns, showCamera, showDelete, name, width } = config;
        const { callbacks } = this.props;
        const images = this.props.record.getValue(name) || [];

        let handleCameraClick;
        let handlePhotoClick;
        let handleDeleteClick;
        if (callbacks && callbacks.ImageGrid) {
            const { onCameraClick, onPhotoClick, onDeleteClick } = callbacks.ImageGrid;
            const update = (newImages) => this.handleChange(name, newImages);

            if (onCameraClick) {
                handleCameraClick = () => onCameraClick(images.slice(), update);
            }

            if (onPhotoClick) {
                handlePhotoClick = () => onPhotoClick(images.slice(), update);
            }

            if (onDeleteClick) {
                handleDeleteClick = (index) => onDeleteClick(index, images.slice(), update);
            }
        }

        return (
            <ImageGrid
                key={key}
                showCamera={showCamera}
                showDelete={showDelete}
                onCameraClick={handleCameraClick}
                onPhotoClick={handlePhotoClick}
                onDeleteClick={handleDeleteClick}
                cellHeight={cellHeight}
                width={width}
                columns={columns}
                images={images}
            />
        );
    }

    /**
     * Render a boolean control
     *
     * @param {object} config - checkbox config
     * @param {string} key - key
     * @returns {Checkbox} checkbox control
     */
    renderBoolean(config, key) {
        const { name, label, readOnly } = config;

        let value, handleChange;

        if (name) {
            value = this.props.record.getValue(name);
            handleChange = (event) => this.handleChange(name, event.isChecked);
        }

        return (
            <Checkbox
                key={key}
                name={name}
                label={this.getStringResource(label)}
                onChange={handleChange}
                checked={value}
                readOnly={readOnly}
            />
        );
    }

    /**
     * Render a tristate control
     *
     * @param {object} config - tristate config
     * @param {string} key - key
     * @returns {Tristate} tristate control
     */
    renderTristate(config, key) {
        const { name, trueLabel, falseLabel, groupLabel, readOnly } = config;

        let value, handleChange;

        if (name) {
            value = this.props.record.getValue(name);
            handleChange = (event) => this.handleChange(name, event.value);
        }

        return (
            <Tristate
                key={key}
                name={name}
                groupLabel={this.getStringResource(groupLabel)}
                checkboxTrueLabel={this.getStringResource(trueLabel)}
                checkboxFalseLabel={this.getStringResource(falseLabel)}
                onChange={handleChange}
                value={value}
                readOnly={readOnly}
            />
        );
    }

    /**
     * Renders the editable table and calls the callbacks on add,edit,delete
     *
     * @param {object} config - the configuration for the control
     * @param {string} key - The key of the control
     * @param {object} record - The record that the data is read from
     * @param {function} onAdd - Callback when the add button is pressed
     * @param {function} onEdit - Callback when the edit button is pressed
     * @param {function} onDelete - Callback when the delete button is pressed
     * @returns {ReadOnlyTable} - The Component to display
     */
    renderEditableTable(config, key, record, onAdd, onEdit, onDelete) {
        const { name, columns, label, minItems, description } = config;

        const value = record.getValue(name);
        let columnsDefinition = convertToReadOnlyTableColumnDefinitions(
            columns,
            this.props.configData,
            this.props.callbacks,
            this.getStringResource
        );

        const translatedLabel = this.getStringResource(label);
        const handleChange = (name, value) => this.handleChange(name, value);
        this.setMandatoryError(ConfigType.TABLE, minItems > 0, name, value, description, translatedLabel, minItems);

        // Need to add the edit/delete buttons at the end of the column definition
        columnsDefinition.push({
            className: 'wcux-form-table-row-btn-container',
            id: 'actions',
            align: 'right',
            actionButtons: [
                {
                    key: 'editBtn',
                    className: 'wcux-form-table-edit-btn',
                    variant: 'discrete-black',
                    icon: <Edit />,
                    handleClick: (e, rowIndex) => onEdit(rowIndex, config, record, handleChange),
                },
                {
                    key: 'deleteBtn',
                    className: 'wcux-form-table-delete-btn',
                    variant: 'discrete-black',
                    icon: <Trash />,
                    handleClick: (e, rowIndex) => onDelete(rowIndex, config, record, handleChange),
                },
            ],
        });

        const actionButtons = [
            {
                className: 'wcux-form-table-add-btn',
                variant: 'primary',
                key: 'addButton',
                icon: <Plus />,
                handleClick: () => onAdd(config, record, handleChange),
            },
        ];

        return (
            <ReadOnlyTable
                key={key}
                className="wcux-editable-table"
                data={value}
                columns={columnsDefinition}
                actionButtons={actionButtons}
                dense={true}
                title={translatedLabel}
                description={this.getStringResource(description)}
                minItems={minItems}
                error={this.showError(name)}
            />
        );
    }

    renderFileAttachment(config, key) {
        // TODO: File attachment is currently not supported. Returns a stub element which appears as empty.
        return <div key={key} />;
    }

    /**
     * Get the string with the specified key
     *
     * @param {string} key - string resource key
     * @returns {string} string with the specified key
     */
    getStringResource(key) {
        if (typeof this.props.getStringResource === 'function') {
            return this.props.getStringResource(key);
        }

        return key;
    }

    /**
     * Set mandatory field error
     *
     * @param {string} type - config type
     * @param {boolean} mandatory - true if mandatory field
     * @param {string} name - field name
     * @param {*} value - field value
     * @param {string} description - field description
     * @param {string} label - field label
     * @param {number} min - minimum value
     */
    setMandatoryError(type, mandatory, name, value, description, label, min) {
        this.mandatoryErrors[name] = {
            showError: false,
            // used to show the field label inside the error dialog,
            // otherwise, if we don't put it here whole config will again have to be traversed to get the field labels
            label: label ? label : name,
        };

        if (!mandatory) {
            return;
        }

        let showError = false;

        if (type === ConfigType.DROPDOWN) {
            // For dropdowns allow empty string as an option
            // If it's an array (multi option), dont allow empty arrays if mandatory
            showError = value === null || (Array.isArray(value) && value.length === 0);
        } else if (type === ConfigType.IMAGEGRID) {
            showError = !Array.isArray(value) || value.length === 0;
        } else if (type === ConfigType.TABLE) {
            showError = !Array.isArray(value) || value.length < min;
        } else {
            showError = value === null || value === '' || (Array.isArray(value) && value.length === 0);
        }

        if (showError) {
            this.mandatoryErrors[name].showError = description ? this.getStringResource(description) : true;
        }
    }

    /**
     * Show error for the given field
     *
     * @param {string} name - field name
     * @returns {string|boolean} error string or true to show error indicator or false for no error
     */
    showError(name) {
        if (this.state.showErrors) {
            return this.getError(name);
        }

        return false;
    }

    /**
     *
     * @param {string} name - name of the field
     * @returns {string|boolean} error string or true to show error indicator or false for no error
     */
    getError(name) {
        if (this.mandatoryErrors[name].showError) {
            return this.mandatoryErrors[name].showError;
        }

        if (this.validationErrors[name]) {
            return this.validationErrors[name];
        }

        return false;
    }

    /**
     * Show all field errors
     */
    showErrors() {
        this.setState({ showErrors: true });
    }

    /**
     * Handle field change event
     *
     * @param {string} name - field name
     * @param {*} value - field value
     */
    handleChange(name, value) {
        let fieldError;

        // enforce pattern (RegEx match) only when value is populated
        // send null back instead of an empty string
        if (this.fieldsWithPattern[name]) {
            if (value && value.length > 0) {
                if (!this.fieldsWithPattern[name].test(value)) {
                    fieldError = true;
                }
            } else {
                value = null;
            }
        }

        this.props.record.setValue(name, value);

        // enforce min length
        if (this.fieldsWithMinLength[name] && value && value.length < this.fieldsWithMinLength[name]) {
            fieldError = true;
        }

        this.validationErrors[name] = fieldError ? true : undefined;

        if (this.props.validation && this.props.validation.validate) {
            let errors = this.props.validation.validate(name, this.props.record);

            if (typeof errors === 'object') {
                Object.assign(this.validationErrors, errors);
            }
        }

        this.setState({ hasChanges: true });

        if (typeof this.props.onFormValueChange === 'function') {
            this.props.onFormValueChange(name, value);
        }
    }

    /**
     * Handle "More" click event
     *
     * @param {string} name - field name
     */
    handleMoreClick(name) {
        if (
            this.props.callbacks &&
            this.props.callbacks[ConfigType.TEXT_DISPLAY] &&
            this.props.callbacks[ConfigType.TEXT_DISPLAY].onMoreClick
        ) {
            this.props.callbacks[ConfigType.TEXT_DISPLAY].onMoreClick(name);
        }
    }

    /**
     * Handle Dropdown action button click event
     *
     * @param {string} name - name of dropdown field
     */
    handleDropdownActionButtonClick(name) {
        if (this.props.callbacks && this.props.callbacks.Dropdown && this.props.callbacks.Dropdown.onActionButtonClick) {
            this.props.callbacks.Dropdown.onActionButtonClick(name);
        }
    }

    /**
     * Handle getting Dropdown action button icon
     *
     * @param {string} name - name of dropdown field
     * @returns {React.Component|HTMLDivElement|string} element or path of action button icon
     */
    getActionButtonIcon(name) {
        if (this.props.callbacks && this.props.callbacks.Dropdown && this.props.callbacks.Dropdown.getActionButtonIcon) {
            return this.props.callbacks.Dropdown.getActionButtonIcon(name);
        }
    }

    /**
     * Validate form
     *
     * @param {object} record - form record
     * @param {object} validation - validation
     */
    validateAll(record, validation) {
        if (validation && validation.validateAll) {
            let errors = validation.validateAll(record);

            if (typeof errors === 'object') {
                this.validationErrors = errors;
            }
        }
    }

    /**
     * Clear the form
     */
    clearForm() {
        let record = this.props.record;
        record.clearRecord();
        this.validateAll(record, this.props.validation);
        this.setState({ hasChanges: true });
    }

    /**
     * Reset the form to its default values
     */
    resetForm() {
        let record = this.props.record;
        record.initData();
        this.validateAll(record, this.props.validation);
        this.setState({ showErrors: false, hasChanges: false });
    }

    /**
     * get expansion panels default state
     *
     * @returns {object} default expansion panels state
     */
    getDefaultExpansionPanelState() {
        const { config } = this.props;
        const expansionPanelState = {};

        if (Array.isArray(config.children)) {
            config.children.forEach((page, pageIndex) => {
                expansionPanelState[pageIndex] = {};
                if (Array.isArray(page.children)) {
                    page.children.forEach((section, sectionIndex) => {
                        if (section.isCollapsible && !section.disableCollapse) {
                            // ignore sections with disableCollapse because those sections are always shown
                            expansionPanelState[pageIndex][sectionIndex] = Boolean(section.defaultExpanded);
                        }
                    });
                }
            });
        }

        return expansionPanelState;
    }

    /**
     * Handle expansion panel change event
     *
     * @param {object} config - config of section
     * @param {boolean} expanded - new expanded state of current section
     * @param {number} sectionKey - section key(index) of current page
     */
    handleExpansionPanelChange(config, expanded, sectionKey) {
        // ignore sections with disableCollapse because those sections are always shown
        if (config.disableCollapse) return;

        const { expansionPanelsExpanded, selectedTab } = this.state;

        let newExpansionPanelsState = { ...expansionPanelsExpanded };
        newExpansionPanelsState[selectedTab][sectionKey] = expanded;
        this.setState({ expansionPanelsExpanded: newExpansionPanelsState });
    }

    /**
     * Renders expansion panels toggler (expand/collapse all)
     *
     * @param {object} config - the Config object for the Page
     * @returns {React.Component|HTMLDivElement} div of expansion panels toggler
     */
    renderExpansionPanelsToggler(config) {
        const { expansionPanelsExpanded, selectedTab } = this.state;
        let expansionToggleEnable = false;
        let expansionList = [];
        if (config.enableExpansionPanelsToggle && config.children.some((section) => section.isCollapsible)) {
            expansionList = Object.keys(expansionPanelsExpanded[selectedTab]).map(
                (sectionKey) => expansionPanelsExpanded[selectedTab][sectionKey]
            );
            expansionToggleEnable = true;
        }

        if (expansionToggleEnable) {
            /**
             * Handles setting the current page's expansion panel state
             *
             * @param {boolean} expanded - boolean to control expand/collapse all panels on page
             */
            const handleExpansionToggle = (expanded) => {
                let newExpansionPanelsState = { ...expansionPanelsExpanded };
                Object.keys(newExpansionPanelsState[selectedTab]).forEach((sectionKey) => {
                    newExpansionPanelsState[selectedTab][sectionKey] = expanded;
                });
                this.setState({ expansionPanelsExpanded: newExpansionPanelsState });
            };

            return (
                <div className="wcux-expansion-panel-toggle-wrapper">
                    {expansionList.includes(false) ? (
                        <div className="wcux-expansion-panel-toggle-expand" onClick={() => handleExpansionToggle(true)}>
                            {this.getStringResource(config.expandAllLabel)}
                        </div>
                    ) : (
                        <div className="wcux-expansion-panel-toggle-collapse" onClick={() => handleExpansionToggle(false)}>
                            {this.getStringResource(config.collapseAllLabel)}
                        </div>
                    )}
                </div>
            );
        }
    }

    /**
     * Construct the title when the expansion panel is collapsed
     *
     * @param {string} title - title of the section
     * @param {object} fieldInfo - right section of the title
     * @return {React.Component|HTMLDivElement} div of collapsed title
     */
    getCollapsedTitle(title, fieldInfo) {
        let collapsedTitle = (
            <div className="wcux-expansion-panel-collapsed-wrapper">
                <div className="wcux-collapsed-title">
                    <div className="wcux-collapsed-title-left">{this.getStringResource(title)}</div>
                    <div className="wcux-collapsed-title-right">{fieldInfo}</div>
                </div>
            </div>
        );

        return collapsedTitle;
    }
}

Form.propTypes = {
    /** Callbacks for form events */
    callbacks: PropTypes.object,
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** Form configuration */
    config: PropTypes.object.isRequired,
    /** Configuration data */
    configData: PropTypes.object.isRequired,
    /** Function for getting a string resource. Signature: getStringResource(key) */
    getStringResource: PropTypes.func.isRequired,
    /** Form record */
    record: PropTypes.object.isRequired,
    /** Form/field validation */
    validation: PropTypes.object,
    /** Array containing mandatory field names.  It supports all types except for Checkbox and Tristate.  FormBuilder does not support this */
    mandatoryFields: PropTypes.arrayOf(PropTypes.string),
    /** Array containing ready only field names. FormBuilder does not support this */
    readOnlyFields: PropTypes.arrayOf(PropTypes.string),
    /** Array containing hidden field names. FormBuilder does not support this */
    hiddenFields: PropTypes.arrayOf(PropTypes.string),
    /** Function for listening to any form on changes, returning the name of the field and the new value */
    onFormValueChange: PropTypes.func,
    /** Host url */
    hostUrl: PropTypes.string,
    /** Callback for getting auth token */
    getToken: PropTypes.func,
};
