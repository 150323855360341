/**
 * @module webcore-ux/LocaleUtils
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import moment from 'moment';

/**
 * Convert date, datetime or time to current locale
 *
 * @param {string} type - type of the column i.e date, datetime or time
 * @param {any} value - value to be formatted
 * @returns {string} date time in the current locale
 */
export const showDateTime = (type, value) => {
    if (!value || !(value instanceof Date || ['string', 'number'].includes(typeof value))) {
        return '';
    }

    if (typeof value === 'string' && !isNaN(value)) {
        // This is assumed to be an epoch ms string. Convert it to a number that moment can handle.
        value = Number(value);
    }

    let dt = moment(value);

    if (type === 'time' && typeof value === 'string') {
        const { TIME_MS, TIME_SECONDS, TIME } = moment.HTML5_FMT;
        dt = moment(value, [moment.ISO_8601, TIME_MS, TIME_SECONDS, TIME]);
    }

    if (dt.isValid()) {
        switch (type) {
            case 'datetime':
                return dt.toDate().toLocaleString();

            case 'date':
                return dt.toDate().toLocaleDateString();

            case 'time':
                return dt.toDate().toLocaleTimeString();
        }
    }

    return '';
};
