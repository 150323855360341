/**
 * @file Eye Hidden Icon
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const EyeHide = (props) => {
    return (
        <SvgIcon {...props} viewBox={'0 0 16 16'}>
            <g id="Box">
                <rect className="cls-1" width="16" height="16" fill="none" />
            </g>
            <g>
                <path
                    fill="#1f1f1f"
                    className="cls-2"
                    d="M8,5a3,3,0,0,1,3,3,3,3,0,0,1-.14.86l2.29,2.29A12.23,12.23,0,0,0,16,8C14,5,11.15,3,8,3a7.61,7.61,0,0,0-2.55.45L7.14,5.14A3,3,0,0,1,8,5Z"
                />
                <path fill="#1f1f1f" className="cls-2" d="M10,8A2,2,0,0,0,8,6Z" />
                <path
                    fill="#1f1f1f"
                    className="cls-2"
                    d="M6.59,6.59h0l-.71-.71h0L4.07,4.07,2,2,1,3,2.87,4.87A12.39,12.39,0,0,0,0,8c2,3.08,4.85,5,8,5a7.86,7.86,0,0,0,2.55-.45L13,15l1-1L8,8ZM8,11A3,3,0,0,1,5,8a3,3,0,0,1,.14-.86L6,8a2,2,0,0,0,2,2l.86.86A3,3,0,0,1,8,11Z"
                />
            </g>
        </SvgIcon>
    );
};

export default EyeHide;
