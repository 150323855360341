/**
 * @module webcore-ux/react/components/GeoLocation
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Input, Dialog } from '../../components';
import classname from 'classnames';
import { default as GeoLocationIcon } from '../Icons/GeoLocation';
import '../../../style/react/components/GeoLocation/GeoLocation';
import ClearIcon from '@material-ui/icons/Clear';

/**
 * Geolocation control
 */
export default class GeoLocation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            geoLocation: props.defaultValue || '',
            error: navigator.geolocation ? null : props.defaultError,
            showExceedAccuracyDialog: false,
            tempGeoLocation: '',
            locationCaptureInProgress: false,
        };

        this.handleCaptureGeoLocation = this.handleCaptureGeoLocation.bind(this);
        this.handleClearContentOnClick = this.handleClearContentOnClick.bind(this);
        this.setGeoLocation = this.setGeoLocation.bind(this);
        this.getGeoLocationError = this.getGeoLocationError.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    render() {
        const {
            id,
            label,
            className,
            name,
            placeholder,
            value,
            mandatory,
            description,
            confirmation,
            error,
            warning,
            captureInProgressMsg,
            exceedThresholdMsg,
            applyButtonText,
            cancelButtonText,
            readOnly,
        } = this.props;

        let inputValue;

        // If value is undefined then this is not a controlled component
        if (value === undefined) {
            inputValue = this.state.geoLocation;
        } else {
            inputValue = value;
        }

        const inputIcons = [
            {
                icon: <GeoLocationIcon onClick={!readOnly ? this.handleCaptureGeoLocation : null} />,
                position: 'start',
                className: 'wcux-geolocation-icon',
            },
        ];

        if (!readOnly && inputValue) {
            inputIcons.push({
                icon: <ClearIcon onClick={this.handleClearContentOnClick} />,
                position: 'end',
                className: 'wcux-clear-icon',
            });
        }

        return (
            <React.Fragment>
                <Input
                    id={id}
                    className={classname('wcux-geolocation', className, {
                        'wcux-geolocation-readonly': readOnly,
                    })}
                    label={label}
                    name={name}
                    icon={inputIcons}
                    readOnly={true}
                    error={this.state.error || error}
                    placeholder={(this.state.locationCaptureInProgress && captureInProgressMsg) || placeholder}
                    value={inputValue}
                    mandatory={mandatory}
                    description={description}
                    confirmation={confirmation}
                    warning={warning}
                />
                {this.state.showExceedAccuracyDialog && (
                    <Dialog
                        className="wcux-geolocation-dialog"
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                        open={true}
                        showCloseButton={false}
                        actionButtons={[
                            {
                                variant: 'discrete',
                                className: 'wcux-geolocation-dialog-cancel-button',
                                key: 'geolocationCancelButton',
                                text: cancelButtonText,
                                handleClick: () => this.handleCloseDialog(false),
                            },
                            {
                                variant: 'primary',
                                className: 'wcux-geolocation-dialog-apply-button',
                                key: 'geolocationApplyButton',
                                text: applyButtonText,
                                handleClick: () => this.handleCloseDialog(true),
                            },
                        ]}
                    >
                        {exceedThresholdMsg}
                    </Dialog>
                )}
            </React.Fragment>
        );
    }

    /**
     * Capture the current location
     */
    handleCaptureGeoLocation() {
        this.setState({
            error: null,
            locationCaptureInProgress: true,
        });

        navigator.geolocation.getCurrentPosition(this.setGeoLocation, this.getGeoLocationError);
    }

    /**
     * Set the input value with latitude, longitude and altitude
     * The format is
     * [lat, long, altitude]
     * When altitude is not available, it will still show the last comma, i.e. [lat, long,]
     * @param {Position} position - Position option from geolocation.getCurrentPosition()
     */
    setGeoLocation(position) {
        let inputValue = position.coords.latitude + ',' + position.coords.longitude + ',' + (position.coords.altitude || '');

        if (this.props.accuracyThreshold > 0 && position.coords.accuracy > this.props.accuracyThreshold) {
            this.setState({
                showExceedAccuracyDialog: true,
                tempGeoLocation: inputValue,
                locationCaptureInProgress: false,
            });
        } else {
            if (this.props.onChange) {
                this.props.onChange({
                    id: this.props.id,
                    name: this.props.name,
                    value: inputValue,
                });
            }

            this.setState({
                geoLocation: inputValue,
                locationCaptureInProgress: false,
            });
        }
    }

    /**
     * Set the correct error message based on the error code
     * @param {PositionError} error - error from getCurrentPosition
     */
    getGeoLocationError(error) {
        let errorMsg = null;

        switch (error.code) {
            case 1:
                errorMsg = this.props.permissionError;
                break;

            case 2:
                errorMsg = this.props.unavailableError;
                break;

            case 3:
                errorMsg = this.props.timeoutError;
                break;

            default:
                errorMsg = this.props.defaultError;
                break;
        }

        this.setState({
            error: errorMsg,
            locationCaptureInProgress: false,
        });
    }

    /**
     * Clear the geo location value
     */
    handleClearContentOnClick() {
        if (this.props.onChange) {
            this.props.onChange({
                id: this.props.id,
                name: this.props.name,
                value: '',
            });
        }

        this.setState({
            geoLocation: '',
            error: null,
        });
    }

    /**
     * Close the dialog box and populate the input text depending on button selection
     * @param {boolean} applyData - true means to apply the geolocation data to the input
     */
    handleCloseDialog(applyData) {
        if (applyData) {
            if (this.props.onChange) {
                this.props.onChange({
                    id: this.props.id,
                    name: this.props.name,
                    value: this.state.tempGeoLocation,
                });
            }

            this.setState({
                geoLocation: this.state.tempGeoLocation,
                showExceedAccuracyDialog: false,
            });
        } else {
            this.setState({
                showExceedAccuracyDialog: false,
            });
        }
    }
}

GeoLocation.defaultProps = {
    permissionError: 'Location capture not permitted. Please check your location settings.',
    unavailableError: 'Current location unknown. Please try again later.',
    timeoutError: 'Location capture timed out. Please try again.',
    defaultError: 'HTML Geolocation not supported',
    exceedThresholdMsg: 'The captured GPS coordinates are outside the accuracy threshold. Do you want to apply these coordinates?',
    captureInProgressMsg: 'Location capture in progress ...',
    applyButtonText: 'Apply',
    cancelButtonText: 'Cancel',
    accuracyThreshold: 10,
};

GeoLocation.propTypes = {
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** true to show a confirmation indicator or a string to show a confirmation indicator and message */
    confirmation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Default input value, useful when not controlling the component */
    defaultValue: PropTypes.any,
    /** Text to display below the input */
    description: PropTypes.string,
    /** true to show an error indicator or a string to show an error indicator and message */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Id of the input element */
    id: PropTypes.string.isRequired,
    /** Label text */
    label: PropTypes.string,
    /** true to show indication that a field is mandatory */
    mandatory: PropTypes.bool,
    /** Name of the input element */
    name: PropTypes.string,
    /** Function to handle value change event. Signature: function ({ id, name, value }) */
    onChange: PropTypes.func,
    /** Placeholder text */
    placeholder: PropTypes.string,
    /**  Input value, required for a controlled component */
    value: PropTypes.any,
    /** true to show a warning indicator or a string to show a warning indicator and message */
    warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Error message when there is permission error for getting location */
    permissionError: PropTypes.string,
    /** Error message when location is unavailable */
    unavailableError: PropTypes.string,
    /** Error message when there is timeout error for getting location */
    timeoutError: PropTypes.string,
    /** Default error message for getting location */
    defaultError: PropTypes.string,
    /** Accuracy threshold */
    accuracyThreshold: PropTypes.number,
    /** Exceed accuracy threshold message */
    exceedThresholdMsg: PropTypes.string,
    /** Capture In Progress message */
    captureInProgressMsg: PropTypes.string,
    /** Text for Apply button */
    applyButtonText: PropTypes.string,
    /** Text for Cancel button */
    cancelButtonText: PropTypes.string,
    /** If true, the user cannot change the location */
    readOnly: PropTypes.bool,
};
