import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import '../../../style/react/components/DayRangePicker/DayRangePicker';
import Logger from 'abb-webcore-logger/Logger';
import { formatDate, parseDate } from 'react-day-picker/moment';
import ErrorCircle1 from '../../../react/components/Icons/ErrorCircle1';
import WarningCircle1 from '../../../react/components/Icons/WarningCircle1';
import CheckMarkCircle1 from '../../../react/components/Icons/CheckMarkCircle1';
import classNames from 'classnames';
import MomentLocaleUtils from 'react-day-picker/moment';

/**
 * Day Range component
 */

export default class DayRangePicker extends React.Component {
    /**
     * Date validation, excluding empty dates:
     * 1) Whether start and/or end date are valid.
     * 2) Whether the start date is before end date.
     * TODO invalid dates coming out of this component are always null. This means Form stores + passes nulls back in, bypassing the invalid checks.
     *
     * @param {string|Date|null} startDate - startDate
     * @param {string|Date|null} endDate - endDate
     * @param {string} dateFormat - date format
     * @returns {Array<string, boolean>} - Tuple [errorMessage, hasErrors]; hasErrors captures the error state even if no error messaging was supplied.
     */
    validateDates(startDate, endDate, dateFormat) {
        if (!startDate && !endDate) {
            return ['', false];
        }

        const errors = [];
        let eDate, sDate;
        if (startDate) {
            sDate = moment(startDate, dateFormat);
            if (!sDate.isValid()) {
                errors.push(this.props.invalidStartDate || '');
            }
        }

        if (endDate) {
            eDate = moment(endDate, dateFormat);
            if (!eDate.isValid()) {
                errors.push(this.props.invalidEndDate || '');
            }
        }

        if (sDate && eDate && eDate.isValid() && sDate.isValid() && sDate.isAfter(eDate)) {
            errors.push(this.props.invalidDateRange || '');
        }

        const errorMessage = errors.filter((text) => text !== '').join(' ');
        return [errorMessage, errors.length > 0];
    }

    /**
     *
     * @param {string} id - id of the input for which this label is being displayed
     * @param {string} label - label text
     * @returns {*} the html label
     * @private
     */
    renderLabel(id, label) {
        return (
            <label htmlFor={id} className={classNames('wcux-label', { 'wcux-mandatory-indicator': this.props.mandatory })}>
                {label}
            </label>
        );
    }

    /**
     * Converts a valid date + format pair into a Moment object/string, depending on input.
     * @param {Date|string} date - The date string or object to transform.
     * @param {string} format - The format, eg. YYYY-MM-DD.
     * @returns {Moment|string|null} - The Moment object or date string. Null if invalid.
     */
    formatDate(date, format) {
        if (!date) {
            return null;
        }

        if (!moment(date, format, true).isValid()) {
            Logger.warn(`Mismatch between date ${date} and format ${format}.`);
            return null;
        }

        return typeof date === 'object'
            ? moment(date).format(format) // Moment with single parameter expects an object, and returns a Moment object
            : moment(date, format); // Moment with 2 parameters expects a string and a format, and returns a string
    }

    render() {
        const {
            id,
            startName,
            endName,
            className,
            format,
            disabled,
            readOnly,
            warning,
            description,
            confirmation,
            startLabel,
            endLabel,
            start,
            end,
            onStartChange,
            onEndChange,
            error,
        } = this.props;

        let message;
        const locale = this.props.locale ? this.props.locale : navigator.language;
        const currentFormatDate = format ? format : moment.localeData(locale).longDateFormat('L');
        const formatUpperCase = currentFormatDate.toUpperCase();
        const startDate = this.formatDate(start, formatUpperCase);
        const endDate = this.formatDate(end, formatUpperCase);
        const [errorMessage, hasError] = error ? [error, true] : this.validateDates(start, end, formatUpperCase);

        const startId = `${id}-start`;
        const endId = `${id}-end`;
        const commonInputProps = {
            format: formatUpperCase,
            formatDate: formatDate,
            parseDate: parseDate,
            placeholder: currentFormatDate.toLowerCase(),
        };

        const from = startDate ? new Date(startDate) : null;
        const to = endDate ? new Date(endDate) : null;

        let modifiers = { start: from, end: to };
        let onDayClick = () => this.to.getInput().focus();
        let disabledStartDays = { disabledDays: { after: to } };
        let disabledEndDays = { disabledDays: { before: from } };
        if (readOnly) {
            modifiers = Object.assign(modifiers, {
                //used to override the default css which removes the backgroundColor
                // if the day is 'selected' and 'disabled' and ('start' or 'end')
                startOrEndReadOnly: [from, to],
                highlighted: { from, to },
                disabled: {
                    // make all days non-clickable when in 'read-only' mode
                    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                },
            });

            onDayClick = null;
            disabledStartDays = {};
            disabledEndDays = {};
        }

        const commonDayPickerProps = {
            selectedDays: [from, to, { from, to }],
            modifiers,
            numberOfMonths: 2,
            localeUtils: MomentLocaleUtils,
            locale,
        };

        if (errorMessage && typeof errorMessage === 'string') {
            // render error message if provided
            message = (
                <div className="wcux-validation-message">
                    <ErrorCircle1 className="wcux-validation-icon" />
                    {errorMessage}
                </div>
            );
        } else if (warning && !hasError) {
            // render warning message if provided
            if (typeof warning === 'string') {
                message = (
                    <div className="wcux-validation-message">
                        <WarningCircle1 className="wcux-validation-icon" />
                        {warning}
                    </div>
                );
            }
        } else if (confirmation && !hasError) {
            // render confirmation message if provided
            if (typeof confirmation === 'string') {
                message = (
                    <div className="wcux-validation-message">
                        <CheckMarkCircle1 className="wcux-validation-icon" />
                        {confirmation}
                    </div>
                );
            }
        } else if (description && !hasError) {
            message = <div className="wcux-validation-message">{description}</div>;
        }

        return (
            <div
                id={id}
                className={classNames('wcux-day-range-picker', className, {
                    'wcux-validation-error': hasError,
                    'wcux-validation-warning': !hasError && warning,
                    'wcux-validation-confirmation': !hasError && !warning && confirmation,
                })}
            >
                <div className="wcux-day-range-picker-date-block">
                    <div className="wcux-day-range-picker-from-container">
                        <div>{this.renderLabel(startId, startLabel)}</div>
                        <DayPickerInput
                            value={from}
                            {...commonInputProps}
                            inputProps={{ readOnly, disabled, name: startName, id: startId }}
                            dayPickerProps={{
                                ...disabledStartDays,
                                ...commonDayPickerProps,
                                toMonth: to,
                                onDayClick: onDayClick,
                            }}
                            onDayChange={onStartChange}
                        />
                    </div>
                    <div>
                        <div>{this.renderLabel(endId, endLabel)}</div>
                        <DayPickerInput
                            ref={(el) => (this.to = el)}
                            value={to}
                            {...commonInputProps}
                            inputProps={{ readOnly, disabled, name: endName, id: endId }}
                            dayPickerProps={{
                                ...disabledEndDays,
                                ...commonDayPickerProps,
                                month: from,
                                fromMonth: from,
                            }}
                            onDayChange={onEndChange}
                        />
                    </div>
                </div>
                <div className="wcux-day-range-picker-break-block">{message}</div>
            </div>
        );
    }
}

DayRangePicker.defaultProps = {
    id: 'default-day-picker',
    startName: 'startDate',
    endName: 'endDate',
    startLabel: 'From',
    endLabel: 'To',
    disabled: false,
    start: null,
    end: null,
    readOnly: false,
    mandatory: false,
    error: undefined,
    invalidStartDate: 'Start Date is not valid.',
    invalidEndDate: 'End Date is not valid.',
    invalidDateRange: 'Start date cannot be greater than the end date.',
};

DayRangePicker.propTypes = {
    /** Id of the dayRange Picker */
    id: PropTypes.string,
    /** name for startDate Input box*/
    startName: PropTypes.string,
    /** name for endDate Input box */
    endName: PropTypes.string,
    /** CSS class name of the dayRange Picker */
    className: PropTypes.string,
    /** Format for date. Overrides the browser locale */
    format: PropTypes.string,
    /** Label to display for start input box */
    startLabel: PropTypes.string,
    /** Label to display for end input box  */
    endLabel: PropTypes.string,
    /** Default start date. Can be a string or a Date object.  */
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    /** Default end date. Can be a string or a Date object.  */
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    /** Locale to use for component */
    locale: PropTypes.string,
    /** Making start and end dates disabled. the overlay calendar doesnt show when user clicks on the inputbox
      so their value cant be changed */
    disabled: PropTypes.bool,
    /** Making inputbox readonly. User can only select from the calendar but cant type in */
    readOnly: PropTypes.bool,
    /** Shows if start and end date are mandatory. default is false  */
    mandatory: PropTypes.bool,
    /** True to show an error indicator or a string to show an error indicator and message */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** True to show a warning indicator or a string to show a warning indicator and message */
    warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Text to display below the dayRange */
    description: PropTypes.string,
    /** true to show a confirmation indicator or a string to show a confirmation indicator and message */
    confirmation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** function is executed when start date is changed  */
    onStartChange: PropTypes.func,
    /** Function to handle end date change event  */
    onEndChange: PropTypes.func,
    /** invalid start date error message */
    invalidStartDate: PropTypes.string,
    /** invalid end date error message */
    invalidEndDate: PropTypes.string,
    /** invalid date range error message when start date is greater than end date*/
    invalidDateRange: PropTypes.string,
};
