/**
 * @module webcore-ux/react/components/Stripe
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import defaultImage from '../../../images/ABB_Logo_Screen_RGB_15px_white_@2x.png';
import '../../../style/react/components/Stripe/Stripe';
import { deprecated } from '../../deprecated';

/**
 * Stripe component that displays the product icon and name
 */
class Stripe extends React.Component {
    render() {
        const { productIcon, productName, productLink } = this.props;

        let imgSrc = defaultImage;
        let cursorPointer = '';

        if (productIcon) {
            imgSrc = productIcon;
        }

        if (productLink) {
            cursorPointer = 'pointer';
        }

        const imageClick = (productLink) => {
            if (productLink) {
                location.href = productLink;
            }
        };

        const imageStyle = {
            cursor: cursorPointer,
        };

        return (
            <div className="wcux-stripe-root">
                <img src={imgSrc} className="wcux-stripe-product-icon" onClick={() => imageClick(productLink)} style={imageStyle} />
                <span className="wcux-stripe-product-name">{productName}</span>
                <span className="wcux-stripe-items">{this.props.children}</span>
            </div>
        );
    }
}

export default deprecated(Stripe, 'Stripe is deprecated. Use Stripev2 instead.');

Stripe.propTypes = {
    /** Children to render with this component */
    children: PropTypes.node,
    /** Path to the product icon to display */
    productIcon: PropTypes.string,
    /** Product name to display */
    productName: PropTypes.string.isRequired,
    /** Product link pointing to landing page or home page upon clicking logo */
    productLink: PropTypes.string,
};
