/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import { ConfigType, INVALID_LIST_DATA_CONFIGURATION } from './Constants';

/**
 * Retrieve data from external source by the form.
 * @param {object} store - store.type is required
 * @returns {object} value - data from storage
 */
export const getDataFromExternal = (store) => {
    let value = null;
    let storageType = store.type;
    let key = store.key;

    if (storageType === 'sessionStorage' || storageType === 'localStorage') {
        if (window[storageType] && window[storageType].getItem) {
            value = window[storageType].getItem(key);
        }
    } else {
        throw new Error(`Type ${storageType} is not supported`);
    }

    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

/**
 * Builds the content of the options list, used by the dropdown and the listitem on the readonly grid.
 * @param {object} data - The data node in the configuration
 * @param {object} configData - config data
 * @param {function} getStringResource - callback for getting localized string
 * @returns {object[]} - array of objects containing label/value properties.
 */
export const buildOptionsListFromDataConfiguration = (data, configData, getStringResource) => {
    let options = [];

    if (!data) {
        return options;
    }

    if (configData) {
        if (data.type === 'lists') {
            if (!configData.lists || !configData.lists[data.name] || !configData.lists[data.name].data) {
                throw new Error(INVALID_LIST_DATA_CONFIGURATION);
            } else {
                configData.lists[data.name].data.forEach((option) =>
                    options.push({
                        label: getStringResource(option.label),
                        value: option.value,
                    })
                );
            }
        } else if (data.type === 'picklists') {
            if (
                !configData.picklists ||
                !configData.picklists.lists ||
                !configData.picklists.lists[data.name].store ||
                !configData.picklists.lists[data.name].store.type
            ) {
                throw new Error(INVALID_LIST_DATA_CONFIGURATION);
            } else {
                let store;
                store = configData.picklists.lists[data.name].store;
                options = getDataFromExternal(store);
            }
        } else if (data.type === 'inline') {
            if (!Array.isArray(data.inlineData) || data.inlineData.length < 1) {
                throw new Error(INVALID_LIST_DATA_CONFIGURATION);
            } else {
                data.inlineData.forEach((option) =>
                    options.push({
                        label: getStringResource(option.label),
                        value: option.value,
                    })
                );
            }
        }
    } else {
        throw new Error(INVALID_LIST_DATA_CONFIGURATION);
    }

    return options;
};

/**
 * Converts the form config column format to the ReadOnlyTable Column format.
 *
 * @param {object[]} columns - The column configuration in the Form Config format
 * @param {object} configData - config data
 * @param {object} callbacks - callbacks
 * @param {function} getStringResource - callback for getting localized string
 * @returns {object[]} - Array of columns in the ReadOnlyTable column format
 */
export const convertToReadOnlyTableColumnDefinitions = (columns, configData, callbacks, getStringResource) => {
    return columns.map((column) => {
        const type = column.columnType || column.type;

        let listItemData, onRender;

        if (type === 'listitem') {
            listItemData = buildOptionsListFromDataConfiguration(column.data, configData, getStringResource);
        }

        if (type === 'reference') {
            if (callbacks && callbacks[ConfigType.TABLE] && callbacks[ConfigType.TABLE].onRenderReference) {
                onRender = (row, col, value) => callbacks[ConfigType.TABLE].onRenderReference({ config: column, value });
            }
        }

        return {
            id: column.name,
            header: getStringResource(column.label),
            columnType: type,
            headerAlign: column.headerAlign,
            align: column.align,
            listItemData,
            onRender,
            'data-testid': column['data-testid'],
        };
    });
};
