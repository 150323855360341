/**
 * @file useHorizontalPanelResizer hook
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import { useEffect, useCallback, useContext, useMemo } from 'react';
import Context from './context';
import { cursorN, cursorS, cursorNS, DRAG_BAR_HEIGHT } from './constants';

/**
 * @param {HTMLDivElement} element a html dom element
 * @return {position} element absolute position in window
 */

function getElementPagePosition(element) {
    let actualLeft = element.offsetLeft;
    let actualTop = element.offsetTop;
    let current = element.offsetParent;
    while (current !== null) {
        actualTop += current.offsetTop + current.clientTop;
        actualLeft += current.offsetLeft;
        current = current.offsetParent;
    }

    return { x: actualLeft, y: actualTop };
}

/**
 * @param {number} initTopPanelHeightPercentage The initial top panel position height to be displayed in the vertical resize wrapper. should be percentage or cell of px default 0
 * @return {string} a css height
 */
const getInitTopPanelHeight = (initTopPanelHeightPercentage = 0) => `${initTopPanelHeightPercentage * 100}%`;
/**
 * @param {number} initTopPanelHeightPercentage The initial top panel position height to be displayed in the vertical resize wrapper. should be percentage or cell of px default 0
 * @return {string} a css height
 */
const getInitBottomPanelHeight = (initTopPanelHeightPercentage = 0) =>
    `calc(${100 - initTopPanelHeightPercentage * 100}% - ${DRAG_BAR_HEIGHT}px)`;

/**
 * @param {object} [propSettings] horizontal panel setting
 * @return {object} { wrapperSettings, dragBarSettings, control, state, settings, bottomPanelSettings, topPanelSettings }
 */
const useHorizontalPanelResizer = (propSettings) => {
    const {
        state: { isDragged, isResizing, isTop, isBottom, wrapperBoundingClientRect, topElementHeight },
        control: { setIsDragged, setTopElementHeight, setIsResizing, setIsTop, setIsBottom, setDefaultValue, setSettings },
        wrapperRef,
        settings,
        dragBarRef,
    } = useContext(Context);

    useEffect(
        () => {
            setSettings((value) => ({ ...value, ...propSettings }));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [propSettings]
    );

    const {
        maxBottom,
        maxTop,
        bottomCollapseSpace,
        topCollapseSpace,
        shouldPreventOverflow,
        initTopPanelHeightPercentage,
        calcTopPanel,
        calcBottomPanel,
        disable,
    } = { ...settings, ...propSettings };

    useEffect(() => {
        const dragBar = dragBarRef.current;
        document.body.addEventListener('mouseup', onMouseUp);
        if (dragBar) {
            dragBar.addEventListener('touchend', onMouseUp, { passive: false });
        }

        return () => {
            document.body.removeEventListener('mouseup', onMouseUp);
            if (dragBar) {
                dragBar.removeEventListener('touchend', onMouseUp, { passive: false });
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragBarRef]);

    /**
     * @param {object} e - mouse event or touch event
     */
    const resize = useCallback(
        (e) => {
            e.preventDefault();

            if (!wrapperRef || !wrapperRef.current) {
                return;
            }

            let pageY = e.changedTouches ? e.changedTouches[e.changedTouches.length - 1].pageY : e.pageY;
            // relative dragBarRef position;
            pageY = pageY - getElementPagePosition(wrapperRef.current).y;

            const resizeMaxTop = maxTop;
            const resizeMaxBottom = maxBottom;
            // the position y of wrapper bottom;
            const wrapperBottom = wrapperRef.current.getBoundingClientRect().height - DRAG_BAR_HEIGHT;

            if (pageY <= topCollapseSpace || resizeMaxTop) {
                document.body.style.cursor = cursorS;
            } else if (pageY >= wrapperBottom - bottomCollapseSpace) {
                document.body.style.cursor = cursorN;
            } else {
                document.body.style.cursor = cursorNS;
            }

            if (pageY <= resizeMaxTop) {
                setTopElementHeight(pageY);
            } else {
                if (pageY < wrapperBottom - resizeMaxBottom) {
                    document.body.style.cursor = cursorNS;
                }

                //make sure drag would not out of boundary
                setTopElementHeight(Math.min(pageY, wrapperBottom));
            }

            setIsTop(pageY <= topCollapseSpace);
            setIsBottom(pageY >= wrapperBottom - bottomCollapseSpace - DRAG_BAR_HEIGHT);
            setIsResizing(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wrapperRef, topCollapseSpace, bottomCollapseSpace, maxTop, maxBottom]
    );

    /**
     * @param {object} e - mouse event or touch event
     */
    const onMouseUp = useCallback(
        (e) => {
            e.preventDefault();
            if (!disable) {
                let isResizingInProgress = false;

                // Only perform mouseUp if resizing is in progress, the only way to get the current value is from the setIsResizing setter callback function
                setIsResizing((value) => {
                    isResizingInProgress = value;
                    return false;
                });

                if (isResizingInProgress) {
                    let pageY = e.changedTouches ? e.changedTouches[e.changedTouches.length - 1].pageY : e.pageY;
                    if (wrapperRef) {
                        // relative dragBarRef position;
                        pageY = pageY - getElementPagePosition(wrapperRef.current).y;
                    }

                    if (pageY <= topCollapseSpace) {
                        setTopElementHeight(maxTop);
                        setIsTop(true);
                    }

                    // The Wrapper Bottom is the wrapper height
                    const wrapperBottom = wrapperRef.current.getBoundingClientRect().height;
                    // When mouse release in bottom collapseSpace area set topElementHeight to bottom
                    if (pageY >= wrapperBottom - bottomCollapseSpace) {
                        setTopElementHeight(wrapperBottom - maxBottom - DRAG_BAR_HEIGHT);
                        setIsBottom(true);
                    }

                    document.body.style.cursor = 'unset';
                    document.body.removeEventListener('mousemove', resize);
                    document.body.removeEventListener('touchmove', resize, { passive: false });
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [shouldPreventOverflow]
    );

    /**
     * @param {object} e - mouse event or touch event
     */
    const onMouseDown = useCallback(
        (e) => {
            e.preventDefault();

            if (!disable) {
                setIsDragged(true);
                document.body.style.cursor = cursorNS;
                document.body.addEventListener('mousemove', resize);
                document.body.addEventListener('touchmove', resize, { passive: false });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [disable]
    );

    const topPanelHeight = useMemo(
        () =>
            isDragged && topElementHeight !== null
                ? calcTopPanel(Math.max(Math.min(topElementHeight, wrapperRef.current.getBoundingClientRect().height), 0))
                : getInitTopPanelHeight(initTopPanelHeightPercentage),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDragged, topElementHeight, wrapperRef]
    );

    const bottomPanelHeight = useMemo(
        () =>
            isDragged && topElementHeight !== null
                ? calcBottomPanel(Math.max(topElementHeight, 0))
                : getInitBottomPanelHeight(initTopPanelHeightPercentage),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [topElementHeight, isDragged]
    );

    return {
        wrapperSettings: {
            ref: wrapperRef,
            'data-testid': 'horizontal-panel-resizer-wrapper',
        },
        dragBarSettings: {
            ref: dragBarRef,
            onMouseDown,
            onTouchStart: onMouseDown,
            'data-testid': 'horizontal-panel-resizer-drag-bar',
        },
        control: {
            setDefaultValue,
        },
        state: {
            isBottom,
            isTop,
            isResizing,
            isDragged,
            topElementHeight,
            boundingClientReact: wrapperBoundingClientRect,
        },
        settings: {
            maxBottom,
            maxTop,
            bottomCollapseSpace,
            topCollapseSpace,
            shouldPreventOverflow,
            initTopPanelHeightPercentage,
            calcTopPanel,
            calcBottomPanel,
            disable,
        },
        bottomPanelSettings: {
            style: {
                overflow: 'hidden',
                height: bottomPanelHeight,
            },
            'data-testid': 'horizontal-panel-resizer-bottom-panel',
        },
        topPanelSettings: {
            'data-top-element-height': topElementHeight,
            style: {
                //prevent top panel render cover bottom panel
                overflow: 'hidden',
                height: topPanelHeight,
            },
            'data-testid': `horizontal-panel-resizer-top-panel`,
        },
    };
};

export default useHorizontalPanelResizer;
