/**
 * @module webcore-ux/react/components/AttachmentControl
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ErrorCircle1 from '../../../react/components/Icons/ErrorCircle1';
import '../../../style/react/components/AttachmentControl/AttachmentControl';
import { Input, Button, Header, ImageGrid, ReadOnlyTable, Lightbox } from '..';

const AttachmentControl = ({
    className,
    showUploadMode,
    title,
    files,
    allowedFileExtensions,
    onInputChange,
    onDeleteClick,
    todayLabel,
    yesterdayLabel,
    zeroByteLabel,
    bytesLabel,
    kbLabel,
    gbLabel,
    tbLabel,
    mbLabel,
    addLabel,
    dir,
    showAttachmentsCount,
    enableLightbox,
}) => {
    const [imagesData, setImagesData] = useState([]);
    const [imagesProcessed, setImagesProcessed] = useState(false);
    const [startIndex, setStartIndex] = useState(-1);
    const readImageFile = (file) =>
        new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });

    const imageFileData = [];
    const nonImageFileData = [];
    const columns = [
        {
            id: 'name',
            hyperlinkDisplayTextField: 'hyperlinkDisplayText',
            columnType: 'hyperlink',
        },
        {
            id: 'size',
            columnType: 'string',
        },
        {
            id: 'lastModifiedDate',
            columnType: 'string',
        },
    ];

    if (showUploadMode) {
        columns.push({
            id: 'col5',
            align: 'right',
            actionButtons: [
                {
                    handleClick: (e, rowIndex) => {
                        handleNonImageDeleteClick(rowIndex);
                    },
                    icon: <ErrorCircle1 />,
                    variant: 'discrete-black',
                },
            ],
        });
    }

    files.forEach((file, index) => {
        if (allowedFileExtensions.includes(file.type)) {
            let newFileObj = { index, file };
            file.type.includes('image/') ? imageFileData.push(newFileObj) : nonImageFileData.push(newFileObj);
        }
    });

    useEffect(() => {
        if (imagesProcessed) {
            return;
        }

        const promises = imageFileData.map((fileObject) => {
            return readImageFile(fileObject.file).then((imageData) => ({ src: imageData, title: fileObject.file.name }));
        });

        Promise.all(promises).then((imageFiles) => {
            setImagesProcessed(true);
            setImagesData([...imageFiles]);
        });
    });

    /**
     * Function to convert date object into readable format
     * @param {Date} formattedDate - Date Object
     * @returns {String} - date string in required format
     */
    const formatDate = (formattedDate) => {
        const date = new Date();
        const isSameDate = date.getDate() === formattedDate.getDate();
        const isSameMonth = date.getMonth() === formattedDate.getMonth();
        const isSameYear = date.getFullYear() === formattedDate.getFullYear();
        if (isSameDate && isSameMonth && isSameYear) {
            return todayLabel;
        } else if (date.getDate() - 1 === formattedDate.getDate() && isSameMonth && isSameYear) {
            return yesterdayLabel;
        } else {
            return formattedDate.getDate() + '/' + (formattedDate.getMonth() + 1) + '/' + formattedDate.getFullYear();
        }
    };

    /**
     * Function to convert bytes into different size units
     * @param {Number} bytes -Bytes
     * @param {Object} i18n - locale Object
     * @returns {String} - size from byte conversion
     */
    const bytesToSize = (bytes) => {
        let sizes = [bytesLabel, kbLabel, mbLabel, gbLabel, tbLabel];
        if (bytes === 0) {
            return zeroByteLabel;
        }

        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    const tableData = nonImageFileData.map((fileObj) => {
        return {
            name: window.URL.createObjectURL(fileObj.file),
            hyperlinkDisplayText: fileObj.file.name,
            size: bytesToSize(fileObj.file.size),
            lastModifiedDate: formatDate(fileObj.file.lastModifiedDate),
        };
    });

    /**
     * @param {Number} index - index of element to delete from imageGrid
     */
    const handleImageDeleteClick = (index) => {
        onDeleteClick(imageFileData[index].index);
        setImagesProcessed(false);
    };

    /**
     * @param {Number} index - index of row to delete from Table
     */
    const handleNonImageDeleteClick = (index) => {
        onDeleteClick(nonImageFileData[index].index);
    };

    /**
     * @param {Object} event - even Object on handle change of Input
     */
    const handleChange = (event) => {
        let files = [...event.target.files];
        const allowedFiles = files.filter((file) => allowedFileExtensions.includes(file.type));
        if (allowedFiles.length !== 0) {
            event.target.value = '';
            setImagesProcessed(false);
            onInputChange(allowedFiles);
        }
    };

    //add attachment section only shown up when showUploadMode is on
    let addAttachmentSection = (
        <div className={'wcux-add-attachment-container'}>
            <Input
                className={classNames('wcux-attachment-file-input', className)}
                id="file-upload"
                onChange={handleChange}
                type="file"
                inputAttr={{ multiple: 'multiple', accept: allowedFileExtensions }}
            />
            <label htmlFor="file-upload">
                {' '}
                <Button size="small" variant="normal" component="span">
                    {' '}
                    {addLabel}{' '}
                </Button>{' '}
            </label>
        </div>
    );

    return (
        <div className={classNames('wcux-attachment-control', className)}>
            {(title || addAttachmentSection) && (
                <Header>
                    {title && (
                        <div className="wcux-attachment-control-title-container">
                            <span className={classNames('wcux-attachment-control-title')}>{title}</span>
                            {showAttachmentsCount && (
                                <span className={classNames('wcux-total-attachments')}>
                                    {imageFileData.length + nonImageFileData.length}
                                </span>
                            )}
                        </div>
                    )}
                    {showUploadMode && addAttachmentSection}
                </Header>
            )}
            <ImageGrid
                showDelete={showUploadMode}
                onDeleteClick={(index) => handleImageDeleteClick(index)}
                cellHeight={200}
                columns={4}
                images={imagesData}
                onImageTileClick={(index) => setStartIndex(index)}
            />
            {enableLightbox && startIndex > -1 && (
                <Lightbox images={imagesData} startIndex={startIndex} onClose={() => setStartIndex(-1)} dir={dir} />
            )}
            <div>
                <ReadOnlyTable columns={columns} data={tableData} showHeaders={false} />
            </div>
        </div>
    );
};

AttachmentControl.defaultProps = {
    cellHeight: 180,
    columns: 2,
    showUploadMode: false,
    width: '100%',
    allowedFileExtensions: ['image/jpeg', 'image/png', 'application/pdf', 'text/plain', 'application/msword'],
    todayLabel: 'Today',
    yesterdayLabel: 'Yesterday',
    zeroByteLabel: '0 Bytes',
    bytesLabel: 'Bytes',
    kbLabel: 'kb',
    gbLabel: 'gb',
    tbLabel: 'tb',
    mbLabel: 'mb',
    addLabel: 'Add',
    dir: 'ltr',
    enableLightbox: true,
    showAttachmentsCount: false,
};

AttachmentControl.propTypes = {
    /** Cell height in px, or 'auto' to let the image determine the height. */
    cellHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** Number of columns to show for the image grid */
    columns: PropTypes.number,
    /** Name for the attachment control */
    name: PropTypes.string.isRequired,
    /** Set to true to show the delete button and add attachment functionality */
    showUploadMode: PropTypes.bool,
    /** Width of the grid */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Title of the attachment control to display */
    title: PropTypes.string,
    /** List of files to show */
    files: PropTypes.array.isRequired,
    /**supported file formats */
    allowedFileExtensions: PropTypes.array,
    /** Callback when Submit is clicked  */
    onInputChange: PropTypes.func,
    /** Callback when Delete is clicked  */
    onDeleteClick: PropTypes.func,
    /** todayLabel of the attachment control to display */
    todayLabel: PropTypes.string,
    /** yesterdayLabel of the attachment control to display */
    yesterdayLabel: PropTypes.string,
    /** zeroByteLabel of the attachment control to display */
    zeroByteLabel: PropTypes.string,
    /** bytesLabel of the attachment control to display */
    bytesLabel: PropTypes.string,
    /** kbLabel of the attachment control to display */
    kbLabel: PropTypes.string,
    /** gbLabel of the attachment control to display */
    gbLabel: PropTypes.string,
    /** tbLabel of the attachment control to display */
    tbLabel: PropTypes.string,
    /** mbLabel of the attachment control to display */
    mbLabel: PropTypes.string,
    /** addLabel of the attachment control to display */
    addLabel: PropTypes.string,
    /**
     * The display direction. dir='rtl' must be supplied for the right-to-left LightBox image scrolling behaviour to work.
     * When 'rtl', the controls reverse, giving the appearance of scrolling images in the opposite direction.
     */
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    /** Set to true to show the Lightbox on image click */
    enableLightbox: PropTypes.bool,
    /** Set to true to show the total attachments count */
    showAttachmentsCount: PropTypes.bool,
};

export default AttachmentControl;
