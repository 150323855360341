/**
 * @module webcore-ux/react/components/ToggleSwitch
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Switch from '@material-ui/core/Switch';
import '../../../style/react/components/ToggleButton/ToggleButton';
import CloseIcon from '../../../react/components/Icons/Close';
import CheckMarkIcon from '../../../react/components/Icons/CheckMark';
import '../../../style/react/components/ToggleSwitch/ToggleSwitch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/**
 * Toggle switch control.
 */
export default class ToggleSwitch extends React.Component {
    constructor(props) {
        super(props);

        this.toggleSwitchRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        const { className, disabled, checked, readOnly, id, name, label } = this.props;

        return (
            <div className={classNames('wcux-toggle-switch')}>
                <FormControlLabel
                    control={
                        <Switch
                            classes={{
                                switchBase: 'wcux-toggle-switch-base',
                                thumb: 'wcux-toggle-switch-thumb',
                                track: 'wcux-toggle-switch-track',
                                checked: 'checked',
                            }}
                            className={classNames('wcux-toggle-switch-root', className, {
                                'wcux-switch-readonly': readOnly,
                                'wcux-switch-disabled': disabled,
                            })}
                            disabled={disabled || readOnly}
                            checked={checked}
                            color="primary"
                            name={name}
                            id={id}
                            icon={<CloseIcon fontSize="small" color="action" fill="white" />}
                            checkedIcon={<CheckMarkIcon fontSize="small" color="action" fill="blue" />}
                            onChange={this.handleChange}
                        ></Switch>
                    }
                    label={label}
                    ref={this.toggleSwitchRef}
                />
            </div>
        );
    }

    handleChange(event) {
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange({
                name: this.props.name,
                isChecked: event.target.checked,
            });
        }
    }
}

ToggleSwitch.defaultProps = {
    disabled: false,
    color: 'default',
    readOnly: false,
};

ToggleSwitch.propTypes = {
    /** Id of the switch element */
    id: PropTypes.string.isRequired,
    /** Name of the component.  Required to differentiate two switches */
    name: PropTypes.string,
    /** CSS class name */
    className: PropTypes.string,
    /** True to disable the Switch */
    disabled: PropTypes.bool,
    /** True to make the Switch read-only (only visual difference in disable and read-only)*/
    readOnly: PropTypes.bool,
    /** label of the Switch */
    label: PropTypes.string,
    /** Icon to show on the Switch. Can be an img src string or React node such as svg. */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** True to render the Switch in the selected state */
    checked: PropTypes.bool,
    /** Color of the Switch */
    color: PropTypes.oneOf(['primary', 'secondary', 'default']),
    /** onChange callback.  Signature: onChange({name, value, isChecked}) */
    onChange: PropTypes.func,
};
