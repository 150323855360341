/**
 * @module webcore-ux/react/components/MainMenu
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../../style/react/components/MainMenu/MainMenu';
import { Button, SlidePanel } from '../';

/**
 * Main Menu component that slides in from the side of the screen.
 * Shows user information, a log off button, any configured menu components, and copyright information.
 */
export default class MainMenu extends React.Component {
    render() {
        const {
            copyrightText,
            className,
            displayNameText,
            emailAddressText,
            signOffButtonText,
            onClose,
            onSignOff,
            dir,
            copyrightTextDir,
            ...other
        } = this.props;

        let emailAddressDiv = null;

        if (emailAddressText) {
            emailAddressDiv = <div className="wcux-mainmenu-emailAddress">{emailAddressText}</div>;
        }

        return (
            <SlidePanel
                variant="temporary"
                anchor={dir === 'rtl' ? 'left' : 'right'}
                open={this.props.open}
                className={classNames('wcux-mainmenu-root', className)}
                theme="dark"
                onClose={onClose}
                {...other}
            >
                <div className="wcux-mainmenu-content">
                    <div className="wcux-mainmenu-upper-section">
                        <div className="wcux-mainmenu-displayName">{displayNameText}</div>
                        {emailAddressDiv}
                        <Button size="small" variant="primary" className="wcux-mainmenu-logoff-button" onClick={() => onSignOff()}>
                            {signOffButtonText}
                        </Button>
                    </div>
                    <div className="wcux-mainmenu-body-section">{this.renderMenuItems()}</div>
                    <div className="wcux-mainmenu-bottom-section">
                        <div className="wcux-mainmenu-copyright" dir={copyrightTextDir}>
                            {copyrightText}
                        </div>
                    </div>
                </div>
            </SlidePanel>
        );
    }

    /**
     * Goes through the menuItems prop and creates the control required to display the menu.
     *
     * @return {React.node|null} - Returns an array of menu items to render, or null if there are no menu items
     */
    renderMenuItems() {
        if (!Array.isArray(this.props.menuItems) || this.props.menuItems.length === 0) {
            return null;
        }

        return this.props.menuItems.map((menuItem, index) => {
            if (menuItem.type === 'heading') {
                return (
                    <div key={index} className={classNames('wcux-mainmenu-row', 'wcux-mainmenu-heading', menuItem.className)}>
                        {menuItem.text}
                    </div>
                );
            } else if (menuItem.type === 'item') {
                return (
                    <input
                        key={index}
                        className={classNames('wcux-mainmenu-row', 'wcux-mainmenu-item', menuItem.className)}
                        type="Button"
                        defaultValue={menuItem.text}
                        onClick={() => this.handleMenuItemClick(menuItem.onClick)}
                    />
                );
            } else {
                return null;
            }
        });
    }

    handleMenuItemClick(callback) {
        if (callback && typeof callback === 'function') {
            callback.apply();
        }
    }
}

MainMenu.defaultProps = {
    open: false,
    dir: 'ltr',
    copyrightTextDir: 'ltr',
};

MainMenu.propTypes = {
    /** ClassName to apply to the main menu's root */
    className: PropTypes.string,
    /** Indicates if the menu should be open or not. The caller is responsible for handling this state. */
    open: PropTypes.bool.isRequired,
    /** Close handler the caller must implement, at a minimum it must set open to false. */
    onClose: PropTypes.func.isRequired,
    /** Event handler for the user sign off button press */
    onSignOff: PropTypes.func.isRequired,
    /** Text to display as the user's name or ID */
    displayNameText: PropTypes.string.isRequired,
    /** Text to display as the user's email address */
    emailAddressText: PropTypes.string,
    /** Text to display in the Sign Off Button, ie: Sign Out, Sign Off, Log Out (users should provide a localized value for this). */
    signOffButtonText: PropTypes.string.isRequired,
    /** Copyright text displayed at the bottom of the menu */
    copyrightText: PropTypes.string.isRequired,

    /**
     * List of menu items to display, if the type is incorrect nothing will be displayed for that entry
     *
     * @type {object[]}
     * @property {string} type - either 'heading' or 'item' indicating the type of the entry
     * @property {string} text - The text to display for the section or item
     * @property {string} [className] - A className to include, if specified
     * @property {function} [onClick] - The callback for the click handler, only applies to type 'item'
     */
    menuItems: PropTypes.array,
    /** Display direction. If set to rtl (right-to-left), this panel slides in from the opposite side of the screen. */
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    /**
     * The copyright text is always English and should be displayed LTR, or the characters become out of order.
     * If for some reason that isn't the case, this prop can control the display direction of the text.
     */
    copyrightTextDir: PropTypes.oneOf(['ltr', 'rtl']),
};
