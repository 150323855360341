/**
 * @module webcore-ux/react/components/MegaMenu
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import CaretDownArrow from '../Icons/CaretDownArrow';
import CaretRightArrow from '../Icons/CaretRightArrow';
import { useState } from 'react';
import { useEffect } from 'react';
import Popup from '../Popup/Popup';
import CaretLeftArrow from '../Icons/CaretLeftArrow';
import '../../../style/react/components/MegaMenu/MegaMenu';
import classNames from 'classnames';

const MegaMenu = (props) => {
    const { menuItems, onMenuItemClick, selectedMenuItem } = props;
    const [selectedMenuItemState, setSelectedMenuItem] = useState(null);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (selectedMenuItem && selectedMenuItem.groupLabel && selectedMenuItem.subItemLabel) {
            setSelectedMenuItem(selectedMenuItem);
        } else {
            // Get the first valid subItem from the first valid group.
            let isMenuItemSet = false;
            for (const column of menuItems) {
                if (Array.isArray(column) && column.length > 0) {
                    for (const row of column) {
                        const { label, items, groupKey } = row;

                        if (!Array.isArray(items) || items.length < 1) {
                            continue;
                        }

                        setSelectedMenuItem({
                            group: groupKey,
                            groupLabel: label,
                            subItem: items[0].subItemKey,
                            subItemLabel: items[0].label,
                        });
                        // Loop until a valid column + row combination is found.
                        isMenuItemSet = true;
                        break;
                    }
                }

                if (isMenuItemSet) {
                    break;
                }
            }
        }
    }, [menuItems, selectedMenuItem]);

    if (!selectedMenuItemState) {
        return null;
    }

    const renderSubItem = ({ label: subItemLabel, subItemKey }, groupKey, groupLabel) => {
        return (
            <div
                className={classNames('wcux-megamenu-sub-item-label', {
                    '-selected': selectedMenuItemState.group === groupKey && selectedMenuItemState.subItem === subItemKey,
                })}
                key={`${groupKey}-${subItemKey}`}
                data-testid={`${groupKey}-${subItemKey}`}
                onClick={() => {
                    if (typeof onMenuItemClick === 'function') {
                        onMenuItemClick({ groupKey, groupLabel, subItemKey, subItemLabel });
                    }

                    setSelectedMenuItem({
                        group: groupKey,
                        groupLabel,
                        subItem: subItemKey,
                        subItemLabel,
                    });

                    setOpen(false);
                }}
                title={subItemLabel}
            >
                {subItemLabel}
            </div>
        );
    };

    renderSubItem.propTypes = {
        label: PropTypes.string.isRequired,
        subItemKey: PropTypes.string.isRequired,
    };

    const renderColumn = ({ label: groupLabel, groupKey, items }) => {
        return (
            <div key={groupKey} data-testid={groupKey} className="wcux-megamenu-group">
                <div data-testid={`${groupKey}-label`} title={groupLabel} className="wcux-megamenu-group-label">
                    {groupLabel}
                </div>
                {Array.isArray(items) && items.length > 0 && items.map((item) => renderSubItem(item, groupKey, groupLabel))}
            </div>
        );
    };

    renderColumn.propTypes = {
        label: PropTypes.string.isRequired,
        groupKey: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                subItemKey: PropTypes.string.isRequired,
            })
        ),
    };

    const { groupLabel: selectedGroupLabel, subItemLabel: selectedSubItemLabel } = selectedMenuItemState;
    return (
        <div data-testid="wcux-mega-menu" className="wcux-megamenu">
            <Button
                variant="discrete-black"
                onClick={(event) => {
                    setOpen(!open);
                    setAnchorEl(event.currentTarget);
                }}
                data-testid="wcux-mega-menu-toggle"
                size="small"
            >
                <div title={`${selectedGroupLabel} - ${selectedSubItemLabel}`} className="wcux-megamenu-btn-selected-value">
                    <div className="wcux-megamenu-btn-selected-group">{selectedGroupLabel}</div>
                    {props.dir === 'rtl' ? <CaretLeftArrow /> : <CaretRightArrow />}
                    <div className="wcux-megamenu-btn-selected-subitem">{selectedSubItemLabel}</div>
                    <CaretDownArrow />
                </div>
            </Button>
            <Popup
                id="wcux-wcux-megamenu-container"
                onOutsideClick={() => setOpen(false)}
                placement="bottom-start"
                anchorEl={anchorEl}
                isOpen={open}
                popupStyleProps={{ root: { maxHeight: 'calc(85vh - 40px)', margin: '0px' } }}
            >
                <div className="wcux-megamenu-container">
                    {Array.isArray(menuItems) &&
                        menuItems.map((columnRows, index) => {
                            if (!Array.isArray(columnRows) || columnRows.length <= 0) {
                                return null;
                            }

                            return (
                                <div key={`column-${index}`} className="wcux-megamenu-column">
                                    {columnRows.map(renderColumn)}
                                </div>
                            );
                        })}
                </div>
            </Popup>
        </div>
    );
};

MegaMenu.defaultProps = {
    dir: 'ltr',
};

MegaMenu.propTypes = {
    /** List of menu items of the form [ [ { label: 'string', group: 'string', subItem: 'string' } ] ] */
    menuItems: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                groupKey: PropTypes.string.isRequired,
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.string.isRequired,
                        subItemKey: PropTypes.string.isRequired,
                    })
                ),
            })
        )
    ).isRequired,
    /**
     * Callback function called when menu item is clicked. It will receive an object as a
     * parameter with the following keys groupKey, groupLabel, subItemKey, subItemLabel
     * @type {function(Object)}
     * @param {Object} menuObject - Object containing fields related to a menu item.
     * @param {string} menuObject.groupKey - group key for the selected menu item
     * @param {string} menuObject.groupLabel - group label for the selected menu item
     * @param {string} menuObject.subItemKey - item key for the selected menu item
     * @param {string} menuObject.subItemLabel - item label for the selected menu item
     */
    onMenuItemClick: PropTypes.func,
    /** Display direction. If set to rtl (right-to-left) */
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    /** Update the selected menu item manually */
    selectedMenuItem: PropTypes.shape({
        group: PropTypes.string.isRequired,
        groupLabel: PropTypes.string.isRequired,
        subItem: PropTypes.string.isRequired,
        subItemLabel: PropTypes.string.isRequired,
    }),
};

export default MegaMenu;
