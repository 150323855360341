/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Filter(props) {
    return (
        <SvgIcon {...props} viewBox={'0 0 32 32'}>
            <polygon points="20 29 18 29 12 23 12 14 3 5 3 3 29 3 29 5 20 14 20 29" />
        </SvgIcon>
    );
}
