/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CaretRightArrow(props) {
    return (
        <SvgIcon {...props}>
            <polygon className="cls-2" points="15 12 9.04 18 8 16.95 12.9 12 8 7.05 9.04 6 15 12" />
        </SvgIcon>
    );
}
