/**
 * @module webcore-ux/react/components/alpha/Search
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { Component } from 'react';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

import Fuse from 'fuse.js';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { PropTypes } from 'prop-types';

import Input from '../../Input/Input';
import ExpansionList from '../../ExpansionList/ExpansionList';
import ExpansionPanel from '../../ExpansionList/ExpansionPanel';
import '../../../../style/react/components/Search/Search';

const KEY_CODES = {
    DOWN_ARROW: 40,
    ENTER: 13,
    TAB: 9,
    UP_ARROW: 38,
};

const NAVIGATION_KEYS = [KEY_CODES.ESCAPE, KEY_CODES.UP_ARROW, KEY_CODES.DOWN_ARROW];

export default class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSuggestions: false,
            selectedIndex: -1,
            input: '', // controlled input
            query: '', // last (input) sent to the api,
            formattedData: {}, // contains groups and array of sorted results
            results: [],
            cachedResults: {}, // empty if online, contains data after first offline search following an online one
            loading: false,
        };
        this.timeout = 0;
    }

    /**
     * @param {object} e - event data
     * Handle change on input change
     */
    handleChange(e) {
        this.setState({ input: e.target.value }, () => {
            this.handleSuggestions();
        });
    }

    /**
     * On Input focus, shows suggestions box
     */
    handleFocus() {
        const { input } = this.state;
        if (input && input.length >= 2) {
            this.setState({ showSuggestions: true });
        }
    }

    /**
     * On Input blur, hide suggestions box
     */
    handleBlur() {
        this.setState({ showSuggestions: false });
    }

    /**
     * Handles suggestions box content.
     * Makes/handles API call to backend:
     *  - if unsuccessful, calls `cachedSearch` to search through last successfull api query
     */
    handleSuggestions() {
        const { input, query, results, cachedResults } = this.state;
        const inputAtLeastTwo = input && input.length >= 2;
        if (!inputAtLeastTwo) {
            this.setState({ showSuggestions: false });
            return;
        }

        // Wait until user stops typing
        if (this.timeout) clearTimeout(this.timeout);

        // Only make the call if it is different
        if (input === query) {
            this.setState({ showSuggestions: true });
            return;
        }

        this.timeout = setTimeout(() => {
            const query = input;
            this.setState({ loading: true });
            this.props.onSearch(query, (res) => {
                const success = res.data; // must be sorted by most relevent

                if (success) {
                    const groupedResults = this.formatResults(success.results);
                    const flatResults = this.flattenResults(groupedResults);

                    this.setState({
                        showSuggestions: true,
                        loading: false,
                        selectedIndex: -1,
                        query: query,
                        formattedData: groupedResults,
                        results: flatResults,
                        cachedResults: {},
                    });
                } else if (res.offline) {
                    if (isEmpty(cachedResults)) {
                        this.setState({ cachedResults: { query: query, results: results } }, () => {
                            this.cachedSearch(query, results);
                        });
                    } else {
                        this.cachedSearch(query, cachedResults.results);
                    }
                }
            });
        }, this.props.debounceTime);
    }

    /**
     * @param {object} e - event data
     * Handles arrow keys up/down and tab
     */
    handleKeyUp(e) {
        if (NAVIGATION_KEYS.indexOf(e.keyCode) !== -1) {
            e.preventDefault();
            this.handleNavigation(e.keyCode);
        } else if (e.keyCode === KEY_CODES.TAB) {
            this.handleBlur();
        }
    }

    /**
     * @param {number} keyCode - HTML keycode from input
     * Increments/decrements `selectedIndex` on up/down arrow keys.
     * `selectedIndex` is used to determine which element is selected=true and sent to `onSubmit()`
     */
    handleNavigation(keyCode) {
        if (!this.state.showSuggestions) return;

        const { selectedIndex, results } = this.state;
        switch (keyCode) {
            case KEY_CODES.UP_ARROW:
                if (selectedIndex !== -1) {
                    this.setState({ selectedIndex: selectedIndex - 1 });
                }

                break;
            case KEY_CODES.DOWN_ARROW:
                if (selectedIndex === -1) {
                    this.setState({ selectedIndex: 0 });
                } else {
                    this.setState({ selectedIndex: (selectedIndex + 1) % results.length });
                }

                break;
            default:
                break;
        }
    }

    /**
     * Handles enter key
     */
    handleEnterKey() {
        if (this.state.selectedIndex !== -1) {
            this.handleSubmit();
        }
    }

    /**
     * Handles `props.onSubmit()`
     * Fired on enter or click
     */
    handleSubmit() {
        const { results, selectedIndex } = this.state;
        const { onSubmit } = this.props;

        if (onSubmit && typeof onSubmit === 'function') {
            onSubmit(results[selectedIndex]);
        }
    }

    /**
     * @param {number} order - matches with index of the item in the results array
     * Mouse enter updates `selectedIndex`
     * `selectedIndex` is used to determine which element is selected=true and sent to `onSubmit()`
     */
    handleMouseEnter(order) {
        this.setState({ selectedIndex: order });
    }

    /**
     * @param {string} string - simple string
     * @returns {JSX.Element}
     * Given a string, checks `wordMatchesQuery` and returns `<span>` with child
     */
    highlightQuery(string) {
        const words = string.split(' ');
        return words.map((word, index) => {
            const lastWord = index + 1 === words.length;
            return (
                <span key={`${word}-${index}`}>
                    {this.wordMatchesQuery(word) || word}
                    {lastWord ? '' : ' '}
                </span>
            );
        });
    }

    /**
     * @param {string} word - simple string
     * @returns {JSX.Element | undefined}
     * Given a word (string without spaces), checks if it is enclosed by `<mark>` from backend, and returns JSX
     */
    wordMatchesQuery(word) {
        // Assume <mark> always encompasses a full word, not just part of one
        const markTagIndex = word.indexOf('<mark>');
        const markEndTagIndex = word.indexOf('</mark>');
        if (markTagIndex >= 0) {
            return <mark className="wcux-search-input-mark">{word.substring(markTagIndex + '<mark>'.length, markEndTagIndex)}</mark>;
        }
    }

    /**
     * @param {array<object>} resData - result array from API callBack
     * @returns {object} - converted to format { 'group1': [], 'group1': [] }
     * Given results from data, converts into object with groups as keys. Results are sorted by these groups
     */
    formatResults(resData) {
        const groupBy = this.props.displayOptions.groupBy;
        const data = resData.reduce((r, a, index) => {
            r[a[groupBy]] = r[a[groupBy]] || [];
            a.order = index;
            r[a[groupBy]].push(a);
            return r;
        }, Object.create(null));

        return data;
    }

    /**
     * @param {object} groupedResults - takes results from `formatResults`
     * @returns {array<object>}
     * Given results from `formateResults`, returns an array similar to the original result array from API
     * This sorts the array so that `selectedIndex` of this array matches with the `order` property
     */
    flattenResults(groupedResults) {
        const sortedResults = [];
        Object.values(groupedResults).forEach((group) => sortedResults.push(...group));
        return sortedResults;
    }

    /**
     * @param {string} query - the current input value
     * @param {array<object>} results - the last successful results
     * When the search API callback fails, cachedSearch is called, this searches though the last successful api call
     * It does this though using `fuse.js` to filter the results.
     * //TODO: Improvement suggestion: cache all successful results and use this method instead of making additional calls
     */
    cachedSearch(query, results) {
        if (isEmpty(results)) {
            this.setState({
                showSuggestions: true,
                loading: false,
                selectedIndex: -1,
                query: query,
            });
            return;
        }

        const resultKeysCache = {};
        results.forEach((res) => Object.assign(resultKeysCache, res));
        const resProperties = Object.keys(resultKeysCache);

        const options = {
            keys: resProperties,
            id: 'id',
        };

        const strippedResults = results.map((item) => {
            return stripHtmlTagFromObj(item, 'mark');
        });

        const fuse = new Fuse(strippedResults, options);

        const searchRes = fuse.search(query);

        const resultsIndexMap = {};
        results.forEach((result) => (resultsIndexMap[result.id] = result));
        const sortedSearchRes = searchRes.map((res) => resultsIndexMap[res]);

        const groupedResults = this.formatResults(sortedSearchRes);
        const flatResults = this.flattenResults(groupedResults);

        this.setState({
            showSuggestions: true,
            formattedData: groupedResults,
            results: flatResults,
            loading: false,
            selectedIndex: -1,
            query: query,
        });
    }

    /**
     * @returns { JSX.Element }
     * Renders the results of the API search
     */
    renderResults() {
        const { formattedData } = this.state;
        const { displayOptions } = this.props;
        const groups = Object.entries(formattedData);

        return (
            <ExpansionList style={{ width: '100%' }}>
                {groups.map(([groupName, groupData]) => (
                    <ExpansionPanel style={{ margin: 0 }} defaultExpanded key={`section-${groupName}`}>
                        <ExpansionPanelSummary
                            classes={{ root: 'wcux-search-panel-summary', content: 'wcux-search-panel-content' }}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <ListSubheader className="wcux-search-subheader">{startCase(groupName)}</ListSubheader>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            style={{
                                flexDirection: 'column',
                                padding: 0,
                            }}
                        >
                            {groupData.map((item) => (
                                <div key={item.id} className="wcux-search-item-row">
                                    <div className="wcux-search-item-category">
                                        <Typography variant="caption" component="span">
                                            {item[displayOptions.displayId] && this.highlightQuery(item[displayOptions.displayId])}
                                        </Typography>
                                    </div>
                                    <ListItem
                                        className="wcux-search-item-li"
                                        button
                                        selected={this.state.selectedIndex === item.order}
                                        onClick={() => this.handleSubmit()}
                                        onMouseEnter={() => this.handleMouseEnter(item.order)}
                                    >
                                        <ListItemText
                                            primary={item[displayOptions.primary] && this.highlightQuery(item[displayOptions.primary])}
                                            secondary={
                                                item[displayOptions.secondary] && this.highlightQuery(item[displayOptions.secondary])
                                            }
                                            style={{ marginTop: 0, marginBottom: 0 }}
                                        />
                                    </ListItem>
                                </div>
                            ))}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </ExpansionList>
        );
    }

    render() {
        const { results, query, cachedResults } = this.state;
        const { placeholder, maxLength, position, noResults, checkNetwork } = this.props;

        return (
            <React.Fragment>
                <Input
                    id="search"
                    type="search"
                    placeholder={placeholder || 'Search...'}
                    inputAttr={{ maxLength: maxLength || 27 }}
                    onKeyUp={(e) => this.handleKeyUp(e)}
                    onEnterKey={() => this.handleEnterKey()}
                    onChange={(e) => this.handleChange(e)}
                    onFocus={() => this.handleFocus()}
                    onBlur={() => this.handleBlur()}
                />
                {this.state.showSuggestions && (
                    // mouseDown is prevented so that user can click within suggestions box without firing blur event
                    <div style={{ position: 'relative' }} onMouseDown={(e) => e.preventDefault()}>
                        <div className={'wcux-search-container'} style={{ right: position === 'right' ? 0 : null }}>
                            <Divider />
                            <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                                {results.length > 0 && this.renderResults()}
                                {results.length === 0 && (
                                    <div className="wcux-search-no-results">
                                        <Typography variant="caption" component="span">
                                            {noResults} <strong>&quot;{query}&quot;</strong>
                                        </Typography>
                                    </div>
                                )}
                                {!isEmpty(cachedResults) && (
                                    <div className="wcux-search-no-results">
                                        <InfoOutlinedIcon fontSize="small" />
                                        <Typography variant="caption" component="span" style={{ fontStyle: 'italic', marginLeft: '.25em' }}>
                                            {checkNetwork}
                                        </Typography>
                                    </div>
                                )}
                            </List>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }

    /**
     * Elastic Search results return an html `<mark></mark>` around the query. This replaces the tags with an empty string. Goes one level deep.
     * @public
     * @param {object} obj - The object to loop through and replace the html tags
     * @param {string} htmlTag - the htmlTag is question, ex: 'mark'
     * @returns {object} - The object any string key values stripped of `htmlTag`
     */
    static stripHtmlTagFromObj(obj, htmlTag) {
        const strippedItem = { ...obj };
        for (let key in strippedItem) {
            const val = strippedItem[key];
            if (typeof val === 'string') {
                strippedItem[key] = val.replace(`<${htmlTag}>`, '').replace(`</${htmlTag}>`, '');
            }
        }

        return strippedItem;
    }
}

Search.defaultProps = {
    debounceTime: 300,
    displayOptions: { displayId: 'displayId', primary: 'name', secondary: 'description', groupBy: 'type' },
    maxLength: 27, // default 27 - see 7. Proper Field Size: https://uxplanet.org/design-a-perfect-search-box-b6baaf9599c
    placeholder: 'Search...',
    position: 'right',
    noResults: 'No search results found for',
    checkNetwork: 'Please check your network connection',
};

Search.propTypes = {
    /** Offline search message */
    checkNetwork: PropTypes.string,
    /** Delay after typing to make search */
    debounceTime: PropTypes.number,
    /** Object fields to display in suggestion results */
    displayOptions: PropTypes.object,
    /** Max length of input */
    maxLength: PropTypes.number,
    /** No search results message */
    noResults: PropTypes.string,
    /** Function to handle the submit event */
    onSubmit: PropTypes.func.isRequired,
    /** Function to handle the search */
    onSearch: PropTypes.func.isRequired,
    /** Placeholder text */
    placeholder: PropTypes.string,
    /** Position of the search component */
    position: PropTypes.oneOf(['left', 'right']),
};

export const stripHtmlTagFromObj = Search.stripHtmlTagFromObj;
