/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ErrorCircle1(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm4.78,14.22-1.56,1.56L12,13.56,8.78,16.78,7.22,15.22,10.44,12,7.22,8.78,8.78,7.22,12,10.44l3.22-3.22,1.56,1.56L13.56,12Z" />
        </SvgIcon>
    );
}
