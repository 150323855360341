/**
 * @module webcore-ux/react/components/LoadingIndicator
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../../style/react/components/LoadingIndicator/LoadingIndicator';

/**
 * Loading indicator control
 */
export default class LoadingIndicator extends React.Component {
    render() {
        const { className, size } = this.props;

        let sizeClass = '';

        if (size === 'small') {
            sizeClass = 'wcux-loading-indicator-small';
        } else if (size === 'large') {
            sizeClass = 'wcux-loading-indicator-large';
        }

        return (
            <div className={classNames('wcux-loading-indicator-root', sizeClass, className)}>
                <div className="wcux-loading-indicator-rect wcux-loading-indicator-rect1" />
                <div className="wcux-loading-indicator-rect wcux-loading-indicator-rect2" />
                <div className="wcux-loading-indicator-rect wcux-loading-indicator-rect3" />
                <div className="wcux-loading-indicator-rect wcux-loading-indicator-rect4" />
                <div className="wcux-loading-indicator-rect wcux-loading-indicator-rect5" />
            </div>
        );
    }
}

LoadingIndicator.defaultProps = {
    size: 'medium',
};

LoadingIndicator.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** Size of the loading indicator */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};
