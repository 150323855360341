/**
 * @module webcore-ux/react/components/Table
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiTable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { getValueFromObj } from 'webcore-common';
import '../../../style/react/components/Table/Table';

//:TRICKY This special field name represents the parent key
// for each object row!
const ROW_NAME_FLD = '__name_field__';

export default class Table extends React.Component {
    constructor(props) {
        super(props);
    }

    /**
     * Returns an array of column headings based on the
     * supplied table config.
     * @param {object} tconfig - config to get headings from
     * @returns {Array<string>} - array of column headings, if any
     */
    getTableHeadings(tconfig) {
        if (!tconfig || !Array.isArray(tconfig.children)) {
            return [];
        }

        let tableConfig = tconfig.children.find((c) => 'table' === c.type);
        let headings = [];
        if (Array.isArray(tableConfig.columns)) {
            headings = tableConfig.columns.map((col) => (col && col.header ? col.header : ''));
        }

        return headings;
    }

    /**
     * Returns an array of field json paths based on the
     * supplied table config.
     * @param {object} tconfig - config to get the field paths from
     * @returns {Array<string>} - array of field json paths, if any
     */
    getTableFields(tconfig) {
        if (!tconfig || !Array.isArray(tconfig.children)) {
            return [];
        }

        let tableConfig = tconfig.children.find((c) => 'table' === c.type);
        let fields = [];
        if (Array.isArray(tableConfig.columns)) {
            fields = tableConfig.columns.map((col) => (col && col.path ? col.path : ''));
        }

        return fields;
    }

    /**
     * Apply i18n conversion if the i18n prop is defined;
     * otherwise, return either the default string, if defined,
     * or the input label.
     * @param {string} label - i18n label
     * @param {string} def - default string to use
     * @returns {string} - the i18ned string, def, or label, whichever is defined
     */
    i18n(label, def) {
        let i = this.props.i18n;
        let str = label;
        if (!i || !i.t || !(i.t instanceof Function)) {
            if (def || '' === def) {
                str = def;
            }
        } else {
            str = i.t(label, def);
        }

        return str;
    }

    /**
     * Builds an array of table rows.
     * @param {array} fields - array of json paths to extract from data
     * @param {object} data - table data
     * @returns {Array<object>} - array of table row data
     */
    buildRows(fields, data) {
        if (!fields || !Array.isArray(fields) || 0 === fields.length) {
            return [];
        } else if (!data || 'object' !== typeof data || 0 === Object.entries(data).length) {
            return [{ [ROW_NAME_FLD]: this.i18n('table.nodata', 'No data') }];
        }

        let rows = Object.keys(data).map((name) => {
            let v = data[name],
                row = [];

            //:TRICKY If the value is not an object,
            // it is placed in the "value" field.
            if (!(v instanceof Object)) {
                row = [{ value: v }];
            } else if (Array.isArray(v)) {
                row = [{ value: v.toString() }];
            } else if (0 < Object.entries(v).length) {
                row = fields.map((f) => {
                    let val = getValueFromObj(v, f, '');

                    // If an object, stringify it
                    if (val instanceof Object) {
                        val = JSON.stringify(val);
                    }

                    return Object.assign({}, { [f]: val });
                });
            }

            // Create one object
            row = row.reduce((a, c) => Object.assign(a, c), {});

            // Then set the parent field
            return Object.assign({}, row, { [ROW_NAME_FLD]: name });
        });

        return rows;
    }

    render() {
        const { classes, tableConfig, tableData, className } = this.props;

        const headings = this.getTableHeadings(tableConfig);
        const fields = this.getTableFields(tableConfig);
        const rows = this.buildRows(fields, tableData);
        const rootStyle = classes && classes.root ? classes.root : '';
        const tableStyle = classes && classes.table ? classes.table : '';
        const headingRowStyle = classes && classes.headingRow ? classes.headingRow : '';
        const rowStyle = classes && classes.row ? classes.row : '';
        const cellStyle = classes && classes.cell ? classes.cell : '';

        return (
            <Paper className={`${rootStyle} ${className}`} square={true}>
                <MuiTable className={tableStyle}>
                    <TableHead>
                        <TableRow className={`${rowStyle} ${headingRowStyle}`} key="firstRow">
                            {headings.map((elem) => (
                                <TableCell
                                    key={elem}
                                    classes={{
                                        root: 'wcux-table-cell-root',
                                        head: 'wcux-table-cell-head',
                                    }}
                                >
                                    {this.i18n(elem)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow className={rowStyle} key={row[ROW_NAME_FLD]}>
                                {fields.map((name) => (
                                    <TableCell
                                        key={row[ROW_NAME_FLD] + '_' + name}
                                        classes={{
                                            root: 'wcux-table-cell-root',
                                            body: 'wcux-table-cell-body',
                                        }}
                                        className={cellStyle}
                                    >
                                        {row[name]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </MuiTable>
            </Paper>
        );
    }
}

Table.propTypes = {
    /** Mandatory table configuration */
    tableConfig: PropTypes.object.isRequired,
    /** Table data */
    tableData: PropTypes.object,
    /** Wrapper element class name */
    className: PropTypes.string,
    /** Custom styling for the wrapper, table, heading, and rows */
    classes: PropTypes.object,
    /** i18n module, and applies translation, if provided */
    i18n: PropTypes.object,
};
