/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Menu(props) {
    return (
        <SvgIcon {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
        </SvgIcon>
    );
}
