/**
 * @module webcore-ux/react/components/TimeSeeker
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import ScrollContainer from 'react-indiana-drag-scroll';
import debounce from 'debounce';
import '../../../style/react/components/TimeSeeker/TimeSeeker';

const MINIMUM_MOVEMENT_FOR_SCROLL = 10;

const useStyles = makeStyles(() => {
    return {
        timeSeekerHour: (props) => ({
            width: `${props.hourWidth}px`,
        }),
    };
});

const TimeSeeker = ({
    className,
    displayTime = moment().format(),
    hourWidth,
    draggable,
    draggableNumberOfHours,
    onDateTimeChanging,
    onDateTimeChanged,
}) => {
    const scrollContainerRef = useRef(null);
    const rootRef = useRef(null);
    const mouseDownXPosition = useRef(null);

    moment.locale(navigator.userLanguage || navigator.language);

    // This is memoized so it's not constantly recreating the same moment over and over.
    const displayTimeMoment = useMemo(() => moment(displayTime), [displayTime]);
    const [topBarDisplay, setTopBarDisplay] = useState(displayTimeMoment.clone());
    const debounceTopBarDisplay = debounce(setTopBarDisplay, 25);
    const [isDragging, setIsDragging] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);

    // screen.width gets the width of the monitor(s).
    // hoursLoaded gets the smallest number of hourWidth sized blocks to fill up the screen plus a buffer of 2 (1 per side) as the time bar will be transformed up to a block.
    const minimumHoursToLoad = Math.ceil(screen.width / hourWidth / 2) * 2 + 2;
    const hoursLoaded = draggable ? Math.max(minimumHoursToLoad, draggableNumberOfHours * 2) : minimumHoursToLoad;
    const classes = useStyles({ minutes: displayTimeMoment.minutes(), hourWidth });
    const timeStart = displayTimeMoment.clone().subtract(hoursLoaded / 2, 'hours');
    const barContent = [];
    const startHourMark = timeStart.clone().set('minute', 0).set('seconds', 0).set('milliseconds', 0);

    const handleMouseDown = useCallback((e) => {
        setIsMouseDown(true);
        mouseDownXPosition.current = e.clientX;
    }, []);

    const handleMouseMove = useCallback(
        (e) => {
            if (Math.abs(mouseDownXPosition.current - e.clientX) > MINIMUM_MOVEMENT_FOR_SCROLL && isMouseDown && !isDragging) {
                // TRICKY: the isDragging can be updated before this callback gets re-built. Having the setter callback function guarantees
                // we can get the correct current value when this is run.  The isDragging check above is not a guarantee, but just there to
                // limit unnecessary calls.
                setIsDragging((dragging) => {
                    if (!dragging) {
                        if (typeof onDateTimeChanging === 'function') {
                            onDateTimeChanging();
                        }

                        return true;
                    }

                    return dragging;
                });
            }
        },
        [onDateTimeChanging, isDragging, isMouseDown]
    );

    const handleMouseUp = useCallback(() => {
        setIsMouseDown(false);
        setIsDragging(false);
        mouseDownXPosition.current = null;
    }, []);

    for (let i = timeStart.hours(); i < timeStart.hours() + hoursLoaded; i++) {
        let increment = i - timeStart.hours();
        let currHourMark = startHourMark.clone().add(increment, 'hours');

        let showDSTBar = currHourMark.isDST() !== currHourMark.clone().add(1, 'hours').isDST();
        let isDSTHour = showDSTBar || currHourMark.isDST() !== currHourMark.clone().subtract(1, 'hours').isDST();

        barContent.push(
            <div
                className={classNames('wcux-time-seeker-hour', classes.timeSeekerHour)}
                key={`wcux-time-seeker-hour-${currHourMark.toISOString(true)}`}
            >
                <span className={classNames('wcux-time-seeker-hour-number', { 'wcux-time-seeker-dst-hour': isDSTHour })}>
                    {currHourMark.format('LT')}
                </span>
                {showDSTBar && <div className="wcux-time-seeker-dst-bar" />}
            </div>
        );
    }

    /**
     * Takes the position of the scrollbar and calculates the corresponding date/time associated with that position.
     * @param {number} position - the scroll position in pixels
     * @returns {string} - The Date/Time ISO string representing the scroll position
     */
    const convertScrollPositionToDateTimeString = (position) => {
        // timeStart + offset will give us the time...
        const hoursPastStartTime = position / hourWidth;

        // Moment will deal with the partial hours for us, so we don't need to calculate down to the minute/second
        return startHourMark.clone().add(hoursPastStartTime, 'hours').toISOString();
    };

    /**
     * Handles the scroll event, so we can update the time at the top with the new time.
     * @param {number} position - the scroll position in pixels
     */
    const handleScroll = (position) => {
        if (isDragging) {
            const halfOfWidthOfTheControl = rootRef.current.clientWidth / 2;
            const newScrollPosition = position + halfOfWidthOfTheControl;
            const newDateTime = convertScrollPositionToDateTimeString(newScrollPosition);

            debounceTopBarDisplay(moment(newDateTime));
        }
    };

    /**
     * Handles the end scroll event so we can calculate the new time and post that up
     * @param {number} position - The position of the scroll
     */
    const handleEndScroll = (position) => {
        // Only perform this work if the user is dragging the bar.
        if (isDragging) {
            const halfOfWidthOfTheControl = rootRef.current.clientWidth / 2;
            const newScrollPosition = position + halfOfWidthOfTheControl;
            const newDateTime = convertScrollPositionToDateTimeString(newScrollPosition);

            // Ensure the top bar has the correct end scroll value
            debounceTopBarDisplay.flush();
            debounceTopBarDisplay(moment(newDateTime));
            debounceTopBarDisplay.flush();

            if (typeof onDateTimeChanged === 'function') {
                onDateTimeChanged(newDateTime);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchend', handleMouseUp);

        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchend', handleMouseUp);
        };
    }, [handleMouseUp]);

    // Mousemove will update frequently, so we don't want to bundle it with mouseUp which will be changed infrequently.
    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchmove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleMouseMove);
        };
    }, [handleMouseMove]);

    useEffect(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const domNode = ReactDOM.findDOMNode(scrollContainerRef.current);

        domNode.addEventListener('mousedown', handleMouseDown);
        domNode.addEventListener('touchstart', handleMouseDown);

        return () => {
            domNode.removeEventListener('mousedown', handleMouseDown);
            domNode.removeEventListener('touchstart', handleMouseDown);
        };
    }, [scrollContainerRef, handleMouseDown]);

    // When the control's props related to the timebar ruler change, scroll to the position of the displayTime
    const scrollOnTimebarChange = useCallback(() => {
        if (isDragging === false) {
            // We need to scroll to the correct time location
            // The correct time is effectively the center + the minute offset
            // Center is half the # of hours loaded (in pixels) - the half the width of the control
            const halfOfHoursLoadedInPixels = (hoursLoaded / 2) * hourWidth;
            const halfOfWidthOfTheControl = rootRef.current.clientWidth / 2;
            const minuteOffsetPixels = (displayTimeMoment.minutes() / 60) * hourWidth;
            const secondsOffsetPixels = (displayTimeMoment.seconds() / 60 / 60) * hourWidth;
            const scrollPosition = halfOfHoursLoadedInPixels - halfOfWidthOfTheControl + minuteOffsetPixels + secondsOffsetPixels;
            // eslint-disable-next-line react/no-find-dom-node
            const domNode = ReactDOM.findDOMNode(scrollContainerRef.current);

            if (domNode.scrollLeft !== scrollPosition) {
                domNode.scrollTo(scrollPosition, 0);
            }
        }
    }, [isDragging, hoursLoaded, hourWidth, displayTimeMoment]);

    useEffect(() => {
        scrollOnTimebarChange();
        window.addEventListener('resize', scrollOnTimebarChange);

        return () => {
            window.removeEventListener('resize', scrollOnTimebarChange);
        };
    }, [scrollOnTimebarChange]);

    // Ensure the top date/time is the same as the displayTimeMoment when it's rendered or updated and the user is not dragging.
    useEffect(() => {
        if (isDragging === false) {
            setTopBarDisplay(displayTimeMoment.clone());
        }
    }, [displayTimeMoment, isDragging]);

    return (
        <div className={classNames('wcux-time-seeker-root', className)} ref={rootRef}>
            <div className={classNames('wcux-date-time-display')}>
                <span className={classNames('wcux-date-display')}>{topBarDisplay.format('L')}</span>
                <span className={classNames('wcux-time-display')}>{topBarDisplay.format('LTS')}</span>
            </div>
            <ScrollContainer
                className={'wcux-time-seeker-bar-scroll-container'}
                horizontal={draggable}
                vertical={false}
                ref={scrollContainerRef}
                onEndScroll={handleEndScroll}
                onScroll={handleScroll}
                activationDistance={MINIMUM_MOVEMENT_FOR_SCROLL}
            >
                <div className={classNames('wcux-time-seeker-bar-container')}>
                    <div className={classNames('wcux-time-seeker-bar')}>{barContent}</div>
                </div>
            </ScrollContainer>
            <div className={classNames('wcux-time-seeker-marker')} />
        </div>
    );
};

// displayTime default is called when defining the component instead of defaultProps for testing purposes.
// If it is placed in defaultProps, it is called on import which is before a mocked version can be created.
TimeSeeker.defaultProps = {
    hourWidth: 144,
    draggable: false,
    draggableNumberOfHours: 24,
};

TimeSeeker.propTypes = {
    /** Class name for the Time Seeker control wrapper */
    className: PropTypes.string,
    /** Date time string, ms timestamp, Date instance or Moment instance, defaults to 'now'.  Note: If dragging, the value of displayTime remains unchanges, you must get the new
        value from the onDateTimeChanged function callback. */
    displayTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.object]),
    /** Width in pixels of each hour block in the time seeker display bar, defaults to 144 */
    hourWidth: PropTypes.number,
    /** How many hours should be scrollable left and right of the current time, this is only useful when draggable = true, if not provided it defaults to 24 **/
    draggableNumberOfHours: PropTypes.number,
    /** Indicates if the time ruler can be dragged to alter the time, see onTimeChanged prop for event info **/
    draggable: PropTypes.bool,
    /** Callback when the user starts to manipulate the date time, (user starts dragging the bar).  function(void) */
    onDateTimeChanging: PropTypes.func,
    /** Callback called when the DateTime is changed by the user (user finished dragging the bar).  function (string) contains an iso date/time string of the new date/time. */
    onDateTimeChanged: PropTypes.func,
};

export default TimeSeeker;
